import { createContext, useEffect, useState } from "react";
import { useFetchCustomerNameListMutation } from "../../redux/users/users.api";
import _ from "lodash";
import { useSelector } from "react-redux";

export const DropdownValueContext = createContext()

export const DropdownProvider= ({ children }) => {
    const [fetchCustomerNameList, { data: customersList, isSuccess }] = useFetchCustomerNameListMutation();
    const { paymentServices } = useSelector((state) => state?.common);
    // const initialValue = paymentServices.find(service => service.isApplicable)?.paymentServiceId || data[0]?.paymentServiceId;
    const [value, setValue] = useState();
    const [customer, setCustomer] = useState();
    const [clickedCard, setClickedCard] = useState(false);

    useEffect(() => {
        fetchCustomerNameList(); // Fetch customer list on mount
    }, []); // Ensures this effect runs only once when the component mounts

    useEffect(() => {
        // Added isSuccess check to ensure data is set only when the fetch is successful
        if (isSuccess && !_.isEmpty(customersList)) {
            setCustomer(customersList[0]?.customerId);
        }
    }, [customersList, isSuccess]); // Listen for changes in customersList and the isSuccess flag

    useEffect(() => {
        if (paymentServices) {
            // Find the first applicable payment service or default to the first item
            const initialValue = paymentServices.find(service => service.isApplicable)?.paymentServiceId || paymentServices[0]?.paymentServiceId;
            setValue(initialValue);
        }
    }, [paymentServices]);


    const changeValue = (v) => {
        setValue(v)
    }
    const changeCustomer = (v) => {
        setCustomer(v)
    }
    const updateClickedCard = (bool) => {
        setClickedCard(bool)
    }
    return (
        <DropdownValueContext.Provider value={{value, customer, changeValue,changeCustomer,customersList, clickedCard, updateClickedCard}}>
            {children}
        </DropdownValueContext.Provider>
    )
};