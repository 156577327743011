import { backendApi } from "../base.config";

export const systemMessagesApi = backendApi.injectEndpoints({
    endpoints: (builder) => ({
        getValueMessage: builder.query({
            query: () => ({
                url:`customerTestRun/getValueNonValueMessageType`,
                method:'GET',
            }),
        }),
        // getValueNonValueMessage: builder.query({
        //     query: (args) => ({
        //         method:'GET',
        //         url:`customerTestRun/getValueNonValueMessages`,
        //         params:{...args}
        //     }),
        //     providesTags: [],
        // })
        getValueNonValueMessage: builder.query({
            query: (args) => {
                if (args?.messageType) {
                // If messageType is provided, make the API call
                return {
                    method: 'GET',
                    url: `customerTestRun/getValueNonValueMessages`,
                    params: { ...args }
                };
              }
            },
            providesTags: [],
        }),
    })
});

export const {
    useGetValueMessageQuery,
    useGetValueNonValueMessageQuery,
} = systemMessagesApi