import React, { useEffect } from 'react';
import Dropdown from '../../shared/components/Dropdown';
import { useGetAllRoutingNumbersQuery } from '../../redux/Reports/reports.api';

const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
            },
        },
    },
}


const RtnDropdown = (props) => {
    const { value, onChangeRtn, customer,paymentValue,reportName } = props;
    const payload = {
        customerId:customer,
        paymentServiceId:paymentValue,
        reportName: reportName 
    }
    const {data:rtnOptions} = useGetAllRoutingNumbersQuery(payload);
    console.log("rtnOptions",rtnOptions);

    useEffect(() => {
        if (!_.isEmpty(rtnOptions)) {
            onChangeRtn(rtnOptions?.id, rtnOptions?.rtn)
        }
    }, [rtnOptions])

    const onChange = (value) => {
        if (value) {
            const obj = _.find(rtnOptions, m => m?.id === value);
            onChangeRtn(value, obj?.Value)
        } else {
            onChangeRtn(value, value)
        }
    }
    return (
        <>
            <Dropdown
                placeholder=' Select Routing Number'
                margin="dense"
                className="user-dropdown"
                style={{ width: '100%', height: '42px', marginTop: '-20px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                options={rtnOptions?.data}
                valueKey='rtn'
                labelKey='rtn'
                onChange={(e) => onChange(e)}
                value={value}
                MenuProps={styles.menuProps}
            />
        </>
    )
}

export default RtnDropdown;