import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { customer_columns } from '../../constants/constants';
import Filter from '../../shared/components/Filter.popup';
import AntModal from '../../shared/components/Modal/AntModal';
import PayaptTable from '../../shared/components/Table';
import { greyBorderColor, primaryColor } from '../../shared/constants/constants';
import useModal from '../../shared/hooks/useModal.hook';
import { customerData } from '../Customers/Constants';
import { getCustomerFilterOptions, customerInitialFilters } from '../homeScreens/TestRun/constants';
import '../styles/message.css';
import TestCaseHeader from "../testCaseHeader";
import { useGetCustomerDataMutation, useGetCustomerTypeListQuery } from '../../redux/customers/customer.api';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { FORM_TYPES } from '../../shared/constants/formTypes';
import { useGetParticipationTypeMutation, useGetParticipationTypeQuery } from '../../redux/customerOnBoarding/customerOnBoarding.api';
import WrapperComponent from '../../shared/components/wrapperComponent';
import { getSortDirection } from '../../utils/sorterUtils';
import { updateCustomerOrganization, updateCustomerTypeName, updateOnboardingCurrentStatus, updateSignUpDetails } from '../../redux/common/common.slice';
import { convertToLocalTime } from '../../shared/utils';
import moment from 'moment';
import MUIButton from '../../shared/components/MUIButton';
import { LeftCircleFilled } from '@ant-design/icons';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';

const Customers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { value } = useContext(DropdownValueContext);
  const columns = customer_columns;
  const pageSize = useSelector((state) => state?.common?.pageSize);
  const [searchedText, setSearchedText] = useState("");
  const [filterOptions, setFilterOptions] = useState([]);
  const [filters, setFilters] = React.useState(customerInitialFilters || {});
  const { open, openModal, closeModal } = useModal();
  const [filterStatus, serFilterStatus] = useState();

  const initialPageOptions = {
    "search": null,
    "page": 0,
    "size": pageSize,
    "filters": {
      "status": null,
      "participationType": null,
      "customerType": null
    },
    "sortCustomerName": null,
    "paymentServiceId": value
  }

  const [pageOptions, setPageOptions] = useState({ ...initialPageOptions });
  const [getCustomerData, { data, isLoading, isSuccess, isError }] = useGetCustomerDataMutation();
  const { data: customerTypeData } = useGetCustomerTypeListQuery();
  const [getParticipation,{ data: participationTypeData }] = useGetParticipationTypeMutation();
  const payload = { 
    paymentServiceId:value
  };

  useEffect(() => {
    if (value) {
      getParticipation(payload).then(res => {
        console.log("Res",res);
      })
    }
    
  },[value])
  React.useEffect(() => {
    setFilterOptions([]);
    if (customerTypeData && participationTypeData) {
      const participationTypes = _.map(participationTypeData, (m, index) => ({
        id: index,
        value: m.participation_type_id,
        label: m.participation_type_name
      }));

      const customerTypes = _.map(customerTypeData, (m, index) => ({
        id: index,
        value: m.customerTypeId,
        label: m.customerTypeName
      }));
      const filterArr = [
        {
          id: '1',
          categoryLabel: "Customer Type",
          categoryName: "customerType",
          subOptions: [
            {
              id: '1',
              filterName: 'customerType',
              filterLabel: 'Select Customer Type',
              filterType: FORM_TYPES.ADD_DROP_DOWN,
              filterOptions: [
                ...customerTypes,
              ]
            }
          ]
        },

        {
          id: '2',
          categoryLabel: "Participation Type",
          categoryName: "participationType",
          subOptions: [
            {
              id: '1',
              filterName: 'participationType',
              filterLabel: 'Select Participation Type',
              filterType: FORM_TYPES.ADD_DROP_DOWN,
              filterOptions: [
                ...participationTypes,
              ]
            }
          ]
        },

        {
          id: '3',
          categoryLabel: "Status",
          categoryName: "status",
          subOptions: [
            {
              id: '1',
              filterName: 'Active',
              filterLabel: 'Active',
              filterType: FORM_TYPES.ADD_CHECK_BOX
            },
            {
              id: '2',
              filterName: 'InActive',
              filterLabel: 'InActive',
              filterType: FORM_TYPES.ADD_CHECK_BOX
            },
            // {
            //   id: '3',
            //   filterName: 'Pending',
            //   filterLabel: 'Pending',
            //   filterType: FORM_TYPES.ADD_CHECK_BOX
            // }
          ]
        },
      ]

      setFilterOptions(filterArr);
    }

  }, [customerTypeData, participationTypeData]);

  async function refetchCustomerQuery(xOptions) {
    setPageOptions(xOptions);
    await getCustomerData({ ...xOptions });
  }

  React.useEffect(() => {
    setSearchedText('');
    setPageOptions(initialPageOptions);
    const xPageOptions = {
      ...initialPageOptions
    }
    setPageOptions(xPageOptions);
  }, []);

  React.useEffect(() => {
    setPageOptions((prevSt) => ({ ...prevSt, search: searchedText }));
    if (searchedText === "") {
      setPageOptions(initialPageOptions)
    }
    // getCustomerData(pageOptions);
  }, [searchedText]);

  useEffect(() => {
    if (value) {
      getCustomerData(initialPageOptions)
    } 
  },[value])

  const updatePageAndGetData = (yPageOptions) => {
    const xPayload = {
      ...pageOptions,
      ...yPageOptions
    }
    setPageOptions(xPayload);
    getCustomerData(xPayload);

  };

  const onSearchChange = (value) => {
    setSearchedText(value);
    setPageOptions({ ...pageOptions, search: value });
    refetchCustomerQuery({ ...initialPageOptions, search: value });
  }


  const handleSaveFilter = async () => {
    let filterStatus = []; // Correctly initialize filterStatus as an array

    if (filters.status.Active && (!filters.status.InActive && !filters.status.Pending)) {
      filterStatus.push(1);
    }
    if (filters.status.InActive && (!filters.status.Active && !filters.status.Pending)) {
      filterStatus.push(2);
    }
    if (filters.status.Pending && (!filters.status.Active && !filters.status.InActive)) {
      filterStatus.push(3);
    }
    if ((filters.status.Active && filters.status.InActive) && (!filters.status.Pending)) {
      filterStatus.push(1, 2);
    }
    if ((filters.status.Active && filters.status.Pending) && (!filters.status.InActive)) {
      filterStatus.push(1, 3);
    }
    if ((filters.status.InActive && filters.status.Pending) && (!filters.status.Active)) {
      filterStatus.push(2, 3);
    }
    if (filters.status.Active && filters.status.InActive && filters.status.Pending) {
      filterStatus.push(1, 2, 3);
    }

    const options = {
      ...initialPageOptions,
      filters: {
        ...pageOptions.filters,
        status: filterStatus?.length > 0 ? filterStatus : null, // Ensure filterStatus is used correctly
        participationType: filters?.participationType?.participationType || null,
        customerType: filters?.customerType?.customerType || null
      }
    };

    setPageOptions(options);
    await getCustomerData(options);
    closeModal();
}
useEffect(() => {
  if (value) {
    setFilters({ ...customerInitialFilters });
  }

},[value])
  const handleCancelFilter = () => {
    // setFilters({ ...customerInitialFilters });
    closeModal();
  };

  const onClear = async() => {
    setFilters({ ...customerInitialFilters });
    setPageOptions(initialPageOptions)
    await getCustomerData(initialPageOptions);
  };


  const handleClick = (rec) => {
    if (rec.customerId) {
      navigate(`/customers/settings/update/${rec.customerId}`);
    } else {
      navigate("/customers/settings/create");
    }
  }

  const handleClickSetting = (rec) => {
    dispatch(updateSignUpDetails(rec.customerId))
    dispatch(updateCustomerOrganization(rec.customerOrganizationName))
    dispatch(updateCustomerTypeName(rec?.customerTypeId))
    console.log('rec', rec?.customerTypeId);
    try {
      dispatch(updateOnboardingCurrentStatus({
        participationType: {
          participationTypeId: rec?.participationTypeId,
          participationTypeName: rec?.participationTypeName,
          isConfigured: false
        }
      }))
    }
    catch (e) {
      console.log('e', e)
    }
    navigate(`/customers/settings`, { state: { customerName: rec.customerOrganizationName } });
  }

  columns[0].render = (data, rec) => (
    <>
      {rec.customerId}
      <br />
      {rec.customerOrganizationName}
    </>
  )

  columns[1].render = (data, rec) => (
    <>
      {rec.customerTypeName}
      <br />
      {rec.participationTypeName}
    </>
  )

  // columns[2].render = (data, rec) => (
  //   <>
  //     RestAPI Connectivity : {rec.restApiConnectivity === 'Yes' ? <CheckCircleOutlineIcon sx={{ color: '#009966', fontSize: 20 }} /> : <CancelOutlinedIcon sx={{ color: '#D65442', fontSize: 20 }} />}
  //     <br />
  //     MQ Setup : {rec.mQSetUp === 'Yes' ? <CheckCircleOutlineIcon sx={{ color: '#009966', fontSize: 20 }} /> : <CancelOutlinedIcon sx={{ color: '#D65442', fontSize: 20 }} />}
  //     <br />
  //     Test Data Review : {rec ? (rec.testDataReview + '%') : ''}
  //     <br />
  //     Certification Readiness:{rec.certificateReadiness}
  //     <br />
  //   </>
  // )

  columns[2].render = (data, rec) => (
    <>
      <span style={{ textTransform: 'lowercase' }}>{rec.validityStartDate && rec.validityEndDate ? moment(convertToLocalTime(rec.validityStartDate)).format('MM-DD-YYYY') + " - " + moment(convertToLocalTime(rec.validityEndDate)).format('MM-DD-YYYY') : null}</span>

    </>
  )


  columns[4].render = (data, rec) => (
    // <a style={{ display: "flex",
    //  flexDirection: "row" 
    //  }}>
    //   <span style={{margin: "0px 5px", border: "1px solid #C6CBD1",backgroundColor:'white',
    // fontSize: "medium",borderRadius: "50%",padding: "5px 5px"}} onClick={() => handleClick(rec)}> <SettingsIcon sx={{color: "#C6CBD1"}}/></span>
    //   <div style={{ margin: "0px 5px", border: "1px solid #C6CBD1", borderRadius: "50%" ,backgroundColor:'white'}}>
    //     <span style={{ padding: "5px 5px" }} onClick={() => handleClickSetting(rec)}><SettingsIcon sx={{ fontSize: "medium", color: "#C6CBD1", marginTop: "5px" }} /></span>
    //   </div>
    // </a>
    <a style={{
      display: "flex",
      flexDirection: "row"
    }}>
      <span onClick={() => handleClick(rec)}>
        {/* <EditIcon /> */}
      </span>
      <div style={{ margin: "0px 5px", border: "1px solid #C6CBD1", borderRadius: "50%", backgroundColor: 'white' }}>
        <span style={{ padding: "5px 5px" }} onClick={() => handleClickSetting(rec)}><SettingsIcon sx={{ fontSize: "medium", color: "#C6CBD1", margin: "5px", marginTop: '10px', marginBottom: '10px' }} /></span>
      </div>
    </a>
  );


  return (
    <>
      <TestCaseHeader
        title="Customer Management"
        subtitle="You can manage all the customers here"
        showSearch={{
          show: true,
          value: searchedText,
          onChange: onSearchChange
        }}
        showFilter={{
          show: true,
          onClick: openModal
        }}
        showUser={{
          show: true,
          onClick: handleClick
        }}
        showTchDropdown={{
          show: true, 
        }}
        displayInLine={true}

      />
      <Box my={2} style={{ textTransform: 'none' }}>
        <WrapperComponent isSuccess={isSuccess} isFetching={isLoading} isError={isError}>
          {/* {value === 1 || value === 2 
          ?
          ( */}
          <PayaptTable
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
            size="small"
            className="standalone_table"
            scroll={{ y: "50vh" }}
            columns={columns}
            data={value === "" ? [] : !_.isEmpty(data) ? data?.list : []}
            rowKey={(rec) => rec?.customerId}
            loading={isLoading}
            onChange={(pagination, filters, sorter) =>
              updatePageAndGetData({
                page: pagination.current - 1,
                search: '',
                sortBy: sorter?.field || pageOptions.sortBy,
                sortDir: getSortDirection(sorter.order) || pageOptions.sortDir,
                pageSize
              })
            }
            pagination={{
              pageSize,
              current: !_.isEmpty(data) ? data.page + 1 : 1,
              total: value === "" ? 0 : !_.isEmpty(data) ? data.count : 0,
              showPrevNextJumpers: true,
              showTitle: true,
              hideOnSinglePage: true,
              size: "small",
            }}
          />
        {/*  ) :
          (
             <Typography style={{marginTop:"10px"}}>NO DATA</Typography>
        //   )
        //   }
           */}
        </WrapperComponent>
      </Box>
      {open && <AntModal
        title="Filter Results for Customer"
        open={open}
        width={'720px'}
        style={{ top: '15%', left: '25%' }}
        mask={false}
        okText="Apply Filter"
        cancelText="Clear"
        onOk={handleSaveFilter}
        footer={[]}
        okButtonProps={{
          style: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: primaryColor
          },
          icon: <CheckCircleIcon sx={{ fontSize: '18px' }} />
        }}
        cancelButtonProps={{
          style: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#F0F2F5',
            color: greyBorderColor
          },
          icon: <CancelIcon sx={{ fontSize: '18px' }} />
        }}
        onCancel={handleCancelFilter}
        closeIcon={<CancelOutlinedIcon sx={{ color: "#001A11", backgroundColor: "white" }} />}
      >
        <Filter filters={filters} setFilters={setFilters} options={filterOptions} />

        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }} px={"2%"} >

          <>
            <MUIButton
              startIcon={<CancelIcon style={{ fontSize: 18, color: greyBorderColor }} />}
              title="Clear"
              sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
              color="inherit"
              onClick={onClear}
            />
            <MUIButton
              startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
              title="Apply Filter"
              onClick={handleSaveFilter}
              sx={{ mx: 2 }}
            />
          </>


        </Box>
      </AntModal>}
    </>
  )
};

export default Customers;
