import { backendApi } from "../base.config";

export const notificationApi = backendApi.injectEndpoints({
    endpoints: (builder) => ({
        notificationMessage: builder.mutation({
            query: (args) => ({
                url:`Settlement/getSettlmentNotificationMessage`,
                method:"POST",
                params:{...args}
            }),
        }),
        notificationXml: builder.mutation({
            query: (args) => ({
                method:"POST",
                url:`Settlement/getSettlmentNotificationXml`,
                params:{...args}
            })
        })
    }),
});

export const {
 useNotificationMessageMutation,
 useNotificationXmlMutation,
} = notificationApi