import { Box, InputAdornment, Typography } from '@mui/material';
import React, { useContext } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import PayaptTextField from '../../shared/components/TextField';
import { INPUT_HEIGHT } from '../../shared/dims';
import { DeleteIcon, ExpandIcon, ExternalLinkIcon } from '../../assets/base64/images';
import { useDrop } from 'react-dnd';
import { useAddTestCaseToBatchMutation, useDeleteTestcaseFromBatchMutation, useGetAllTestcasesOfBatchQuery } from '../../redux/batchFiles/batchFile.api';
import PayaptLoader from '../../shared/components/Spinner';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { ALERT_SEVERITY } from '../../shared/constants/constants';
import { BatchFileMsg } from '../../shared/constants/AlertMessages';
import BatchBoxTitle from '../BatchFiles/BatchBoxTitle';
import DraggableTestcase from '../BatchFiles/draggableTestcase';
import { useSelector } from 'react-redux';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';


const styles = {
    container: {
        display: "flex",
        flexDirection: "column"
    },
    boxContainer: {
        border: '1px solid rgba(0,0,0, 0.1)',
        p: 2,
        height: '55vh',
        overflowY: 'auto'
    },
    searchContainer: {
        width: "100%",
    },
    contentContainer: {
        // p: 2
        mt: 1,
        maxHeight: "50vh",
        overflowY: "auto"
    },
    cardStyle: {
        width: '100%',
        display: "flex",
        alignItems: "flex-start",
        px: 2,
        backgroundColor: "rgba(0,0,0, 0.05)",
        my: 2,
        py: 2,
        borderRadius: '4px'
    },
    inputSx: {
        '.MuiOutlinedInput-notchedOutline': {
            borderRadius: '4px'
        },
        '.MuiInputBase-root': {
            height: INPUT_HEIGHT
        }
    }
}

function TestCaseSelection(props) {
    const { selectedBatch, openBatchTestcaseDetails, onBatchTestDataSelectionChange, selectedTestcases,
        // customer 
    } = props;
    const [searchTerm, setSearchTerm] = React.useState("");
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const { customer } = useContext(DropdownValueContext);
    const { customerId } = useSelector((state) => state?.common);
    const [addedTestcase, setAddedTestcase] = React.useState(null)
    const [deleteTestcaseFromBatch] = useDeleteTestcaseFromBatchMutation();
    const [addTestCase] = useAddTestCaseToBatchMutation();
    const Snackbar = useContext(SnackbarContext);
    const getPayload = () => {
        const options = {
            batchId: selectedBatch?.customerBatchId,
            customerId:(userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer
        }
        if (searchTerm) {
            options.testCaseName = searchTerm;
        }
        return options;
    }
    const { data, isFetching, refetch } = useGetAllTestcasesOfBatchQuery(
        { ...getPayload() }, {
        skip: _.isEmpty(selectedBatch?.customerBatchId) // this will skip the fetchCall when testCaseScenarioId value is empty
    }
    )

    const addNewTestCase = async (item) => {
        try {
            await addTestCase({ batchId: item?.batchId, testCaseId: item?.testCaseId });
            Snackbar.displayMsg(BatchFileMsg.ADD_TESTCASE_SUCCESS, ALERT_SEVERITY.SUCCESS);
        } catch (error) {
            console.log(error)
            Snackbar.displayMsg(BatchFileMsg.ADD_TESTCASE_FAIL, ALERT_SEVERITY.ERROR);
        }
    }
    const [{ isOver }, dropRef] = useDrop(() => ({
        accept: 'TC',
        drop: (item) => {
            addNewTestCase(item)
        },
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    }))

    const { testCases } = props;

    const handleOnSearchChange = (xValue) => {
        setSearchTerm(xValue);
    }

    const deleteTestcase = async (e, testcase) => {
        try {
            await deleteTestcaseFromBatch({ testCaseId: testcase?.testCaseId, batchId: selectedBatch?.customerBatchId })
            Snackbar.displayMsg(BatchFileMsg.DELETE_TESTCASE_SUCCESS, ALERT_SEVERITY.SUCCESS);
            refetch();
        } catch (error) {
            console.log(error)
            Snackbar.displayMsg(BatchFileMsg.DELETE_TESTCASE_FAIL, ALERT_SEVERITY.ERROR);
        }
    };

    const getSelectedTestData = (testcase) => {
        const tCase = _.find(selectedTestcases?.caseAndDatas, s => s.testCases === testcase?.testCaseId);
        return tCase?.testDatas;
    };
    
    return (
        <Box sx={styles.container}>
            <BatchBoxTitle title={`Test Cases inside Batch File (${_.size(data)})`} />
            <Box sx={styles.boxContainer} ref={dropRef}>
                <Box sx={styles.searchContainer}>
                    <PayaptTextField
                        fullWidth
                        sx={styles.inputSx}
                        placeholder="Search here ..."
                        value={searchTerm}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                        onChange={(event) => handleOnSearchChange(event.target.value)} />
                </Box>
                {data?.length !== 0 && data !== undefined ?
                (<Box sx={styles.contentContainer} >
                    {isFetching && <PayaptLoader />}
                    {data?.map((xRes, xIdx) => (
                        <DraggableTestcase
                            title={xRes?.testCaseName}
                            className="without-sharp"
                            // onClick={() => {
                            //     setRowIndex(i);
                            //     console.log('index', i);
                            // }}
                            index={xIdx}
                            item={xRes}
                            showExpandIcon={true}
                            actions={[
                                {
                                    icon: <ExternalLinkIcon />,
                                    onClick: openBatchTestcaseDetails
                                },
                                {
                                    icon: <DeleteIcon />,
                                    onClick: deleteTestcase
                                }
                            ]}
                            onSubItemSelect = {(e, testdata) => onBatchTestDataSelectionChange(e, testdata, xRes, selectedBatch)}
                            selectedItems = {getSelectedTestData(xRes)}
                        />

                    ))}
                </Box>) : _.isEmpty(selectedBatch?.customerTestRunBatchId) ? 'SELECT ANY BATCH FILE' : 'NO DATA'
            }
            </Box>
        </Box>
    );
}


export default TestCaseSelection;