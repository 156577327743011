import React, { useEffect } from "react";
import { isEmpty } from "lodash";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

//TODO: need to be replaced with MUI icons
import { CloseCircleOutlined, InfoCircleFilled } from "@ant-design/icons";

import TestCaseSubDetails from "./testcaseSubDetails";
import ExecutionStep from "./executionStep";
import { useGetAllMasterTcVersionMasterMutation } from "../../redux/testCases/testCases.api";



const ViewTestCaseMaster = (props) => {
  const { testcase: parentTestCaseRec, onClose, isBatchMode} = props;
  const { testCaseId = "" } = parentTestCaseRec;

  const [pageOptions, setPageOptions] = React.useState({
    pageNo: 0,
    pageSize: 1,
    testCaseId: testCaseId,
  });
  console.log('testCaseId',testCaseId);
  console.log('testCaseId-pageOptions',pageOptions);
  const [getTestCaseDetails, { data, isLoading }] = useGetAllMasterTcVersionMasterMutation();
 
  useEffect(() => {
    if (!testCaseId || testCaseId === "") {
      return;
    }
    getTestCaseDetails({...pageOptions});
  }, [testCaseId]);

  const testcase =
    data && !isEmpty(data?.testCaseVersionData)
      ? data?.testCaseVersionData
      : {};
  const testCaseStepData =
    data &&
    !isEmpty(data?.testCaseStepData) &&
    data?.testCaseStepData?.length > 0 &&
    data?.testCaseStepData;

  const prevButtDisable = testcase?.previous === "No Data Found";
  const nextButtDisable = testcase?.next === "No Data Found";

  const handlePrevClick = (xNavId) => {
    getTestCaseDetails({
      ...pageOptions,
      testCaseId: xNavId
    });
    // getData({
    //   pageNo: 0,
    //   pageSize: 1,
    //   testCaseId: xNavId,
    // });
  };
  const handleNextClick = (xNavId) => {
    // getData({
    //   pageNo: 0,
    //   pageSize: 1,
    //   testCaseId: xNavId,
    // });
    getTestCaseDetails({
      ...pageOptions,
      testCaseId: xNavId
    });
  };

  if (isLoading) {
    return (
      <div
        className="testcase-detail-header"
        style={{ backgroundColor: "#ffffff", height: "100%" }}
      >
        <CircularProgress className="data-loader" />
      </div>
    );
  }

  return (
    <div className="testcase-detail-container">
      <div className="testcase-detail-header">
        <span className="close-circle">
          <CloseCircleOutlined
            style={{ color: "background: #001A11", fontSize: 16 }}
            onClick={() => onClose(false)}
          />
        </span>
        <span className="onboarding-step-title header1">
          View Test Case&nbsp;&nbsp;
          {/* <InfoCircleFilled className="step-info-icon" /> */}
        </span>
        {!isLoading && !isEmpty(testcase) && (
          <>
            <div className="subtitle-buttons-container">
              <span className="onboarding-step-description header2-italic">
                Viewing Test Case Details for{" "}
                <span className="testcase-name">{testcase?.testCaseName}</span>
              </span>
              {
                !isBatchMode &&
              <div className="prev-next-buttons">
                <Button
                  variant="text"
                  disabled={prevButtDisable}
                  sx={{
                    color: `#000`,
                    textTransform: "initial",
                    "& .MuiButton-startIcon": { marginRight: 0 },
                  }}
                  onClick={() => handlePrevClick(testcase?.previous)}
                  startIcon={<ArrowBackIosIcon sx={{ fontSize: "8px" }} />}
                >
                  Prev
                </Button>
                <Button
                  variant="text"
                  disabled={nextButtDisable}
                  sx={{
                    color: `#000`,
                    textTransform: "initial",
                    "& .MuiButton-startIcon": { marginLeft: 0 },
                  }}
                  onClick={() => handleNextClick(testcase?.next)}
                  endIcon={<ArrowForwardIosIcon sx={{ fontSize: "8px" }} />}
                >
                  Next
                </Button>
              </div>
            }
            </div>
          </>
        )}
      </div>
      {!isLoading && !isEmpty(testcase) && (
        <div className="testcase-details-container">
          <div className="testcase-details-row">
            <div className="testcase-detail-item">
              <span className="testcase-detail-item-header">Test Case Id</span>
              <span className="testcase-detail-item-value">
                {testcase?.testCaseName}
              </span>
            </div>
            <div className="testcase-detail-item">
              <span className="testcase-detail-item-header">
                FedNow Message Flow Name
              </span>
              <span className="testcase-detail-item-value">
                {testcase?.MessageFlowName}
              </span>
            </div>
          </div>
          <div className="testcase-details-row">
            <div className="testcase-detail-item">
              <span className="testcase-detail-item-header">
                Trigger Message Type
              </span>
              <span className="testcase-detail-item-value">
                {testcase?.triggerMessageType}
              </span>
            </div>
            <div className="testcase-detail-item">
              <span className="testcase-detail-item-header">Direction</span>
              <span className="testcase-detail-item-value">
                {testcase?.inboundOutbound}
              </span>
            </div>
          </div>
          <div className="testcase-details-row">
            <div className="testcase-detail-item">
              <span className="testcase-detail-item-header">Version</span>
              <span className="testcase-detail-item-value">
                {testcase?.testCaseVersionNo}
              </span>
            </div>
            <div className="testcase-detail-item">
              <span className="testcase-detail-item-header">
                Test Data Required
              </span>
              <span className="testcase-detail-item-value">
                <a>View File</a>
              </span>
            </div>
          </div>
          <div>
            <div>
              <TestCaseSubDetails
                title="Description"
                titleValue={testcase?.testCaseDescription}
                subtitle="Prerequisite"
                subTitleValue={"NA"}
              />
            </div>
            <div>
              <TestCaseSubDetails
                title="Scenario"
                titleValue={testcase?.scenario}
              />
            </div>
            <div>
              <TestCaseSubDetails
                title="Entities Involved"
                titleValue={testcase?.entityInvolved}
              />
            </div>
          </div>
          {/* <Box>
            <ExecutionStep testCaseStepData={testCaseStepData} />
          </Box> */}
        </div>
      )}
    </div>
  );
};

export default ViewTestCaseMaster;
