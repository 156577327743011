export const primaryColor = "#009966";
export const greyBorderColor = "#808080";
export const redColor = "#DD2025";

export const ALERT_SEVERITY = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info',
};

export const APP_ROUTE_KEYS = {
    DASHBOARD: {
        MASTER: "/dashboard"
    },
    SIMULATOR: {
        MASTER: "/simulator"
    },
    BATCHES: {
        MASTER: "/batches",
    },
    MESSAGE: {
        MASTER: "/message",
        XML_LIST: "xmlList",
        TEMPLATE: "template",
        INCOMING_TEMPLATE: "incomingTemplate",
        XML_VALIDATE_REPORT: "xmlValidateReport",
        OUT_GOING_XML_LIST: "outGoingXmlList",
    },
    TEST_CASES: {
        MASTER: "/scenario",
        RETURN_REQUEST : "/scenario/returnrequst",
        PAYMENT_RETURN : "/scenario/paymentreturn",
        PAYMENT_CANCELLATION : "/scenario/paymentCancellation",
        PAYMENT_STATUS_REQUEST : "/scenario/paymentStatusRequest",
        INFORMATION_REQUEST_RESPONSE : "/scenario/informationRequestResponse",
        REQUEST_FOR_PAYMENT_RESPONSE : "/scenario/paymentForPaymentResponse",
    },
    TEST_RUNS: {
        MASTER: "/execution",
        SUMMARY: "/execution/summary",
    },
    SETTLEMENT: {
        MASTER: "/settlement"
    },
    SETTINGS: {
        MASTER: "/settings",
        CHOOSE_PLAN:"/settings/subcriptionPlan",
        CHOOSE_TYPE:"/settings/participationType",
        CONFIG_TYPE:"/settings/configureParticipationType",
        TEST_DATA: "/settings/testData",
        SIM_DATA: "/settings/simData",
        ACCOUNT_INFO: "/settings/accountInfo",
        EOD_PROCESS:"/settings/eodProcess",
        CONFIG_PARAMS:"/settings/configParams",
        STIMULATOR_TEST_DATA_NEW_TABLE:"/setting/stimulatorTestDataNewTable",
        ORGANIZ_DETAILS:"/settings/organizationDetails",
        CONNECTIVITY:"/settings/connectivity",
        SETTLEMENT_ACCOUNT:"/settings/settlementAccountTransaction",
        RESPONDENT_BANK:"/settings/respondentBank",
        ACCOUNT_INFO_SETTINGS:"/settings/accountInfoTab",
        RESPONDENT_RTN_SETTINGS:"/settings/respondentBank/routingNumber"
    },
    USERS: {
        MASTER: "/users"
    },
    REPORTS: {
        MASTER: "/reports",
        EOD_REPORTS:"/reports/EODReports",
        RETRIEVAL_REQUEST_ADMI006:"/reports/retrievalRequest",
        MESSAGE_ADMI006:"/reports/retrievalRequest/Admi006",
        SYSTEM_MESSAGES:"/reports/systemMessages",
        FEDNOW_PARTICIPANT:"/reports/fednowParticipantFile",
    },
    CUSTOMERS:{
        MASTER:"/customers",
        MASTER_SETTINGS: "/customers/settings",
        CREATE_DATA:"/customers/settings/create",
        UPDATE_DATA:"/customers/settings/update",
        CHOOSE_PLAN_SETTINGS:"/customers/settings/subcriptionPlan",
        CHOOSE_TYPE_SETTINGS:"/customers/settings/participationType",
        CONFIG_TYPE_SETTINGS:"/customers/settings/configureParticipationType",
        TEST_DATA_SETTINGS: "/customers/settings/testData",
        SIM_DATA_SETTINGS: "/customers/settings/simData",
        ACCOUNT_INFO_SETTINGS: "/customers/settings/accountInfo",
        EOD_PROCESS_SETTINGS:"/customers/settings/eodProcess",
        CONFIG_PARAMS_SETTINGS:"/customers/settings/configParams",
        STIMULATOR_TEST_DATA_NEW_TABLE_SETTINGS:"/customers/settings/stimulatorTestDataNewTable",
        ORGANIZ_DETAILS_SETTINGS:"/customers/settings/organizationDetails",
        CONNECTIVITY_SETTINGS:"/customers/settings/connectivity",
        SETTLEMENT_ACCOUNT:"/customers/settings/settlementAccountTransaction",
        RESPONDENT_BANK:"/customers/settings/respondentBank",
        ACCOUNT_INFO_TAB:"/customers/settings/accountInfoTab",
        RESPONDENT_RTN:"/customers/settings/respondentBank/routingNumber"
    },
    USER_AUDIT_LOG: {
        MASTER: "/activityLogs"
    },
    NOTIFICATIONS:{
        MASTER:"/notifications"
    },
    UPLOAD_TESTCASE:{
        MASTER: "/upload/testcases"
    },
    TEST_CASES_REPO:{
        MASTER: "/testcaseRepo",
        UPLOAD_TESTCASE: "/upload/testcases",
    },
    PRE_REQUISITE_DATA:{
        MASTER :"/preRequisiteData"
    },
    SYSTEM_MESSAGES:{
        MASTER :"/systemMessages/Admi006",
        RETRIEVAL_REQUEST:"/systemMessages/Admi006/retrievalRequest",
    }
}