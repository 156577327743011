import { Box, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import TestCaseHeader from '../testCaseHeader';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DeleteOutlined } from '@ant-design/icons';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { ALERT_SEVERITY } from '../../shared/constants/constants';
import MUIButton from '../../shared/components/MUIButton';
import Dragger from 'antd/es/upload/Dragger';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UploadIcon from '@mui/icons-material/Upload';


const UploadPreRequisite = () => {
    const Snackbar = useContext(SnackbarContext);
    const { value, customer } = useContext(DropdownValueContext);
    const { customerId } = useSelector((state) => state?.common);
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const [fileList, setFileList] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [totalRecords, setTotalRecords] = useState(null);
    const [recAddedRecords, setRecAddedRecords] = useState(null);
    const [updatedRecords, setUpdatedRecords] = useState(null);

    const formData = new FormData();
    const token = localStorage.getItem('jwttoken');

    const handleDelete = () => {
        setSelectedFile(null);
        setFileList([]);
    };

    const closeModal = () => {
        setModalVisible(false);
        setModalContent('');
    };

    const handleFileChange = (file) => {
        setSelectedFile(file);
        setFileList([
            {
                uid: file.uid,
                name: file.name,
                status: 'done',
                url: URL.createObjectURL(file),
            },
        ]);
    };

    const handleUpload = (file) => {
        console.log("FILE", file);
        formData.append('file', selectedFile)
        formData.append('customerId', (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer)
        formData.append('paymentServiceId', value)
        uploadTestCasePre(formData)
    }

    const uploadTestCasePre = () => {
        setIsLoading(true)

        fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/Simulator/uploadPrerequistedData`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("ressssssssppppppp", data);
                if (data.status == "success") {
                    setIsLoading(false)
                    setModalContent('File Uploaded Successfully');
                    setTotalRecords(data.totalRecords);
                    setRecAddedRecords(data.recentlyAddedRecords);
                    setUpdatedRecords(data.updatedRecords);
                    setModalVisible(true);
                    // Snackbar.displayMsg(`File Uploaded Successfully`, ALERT_SEVERITY.SUCCESS)
                    setSelectedFile(null)
                    setFileList([])
                    formData.delete('file')
                    setTimeout(() => {
                        closeModal();
                    }, 5000);
                } else {
                    Snackbar.displayMsg(`File Upload Failed.`, ALERT_SEVERITY.ERROR);
                }
            })
            .catch(error => {
                Snackbar.displayMsg(`File Upload Failed.`, ALERT_SEVERITY.ERROR);
            });
        setSelectedFile(null)
        formData.delete('file')
    };

    const handleDownload = async() => {
        const token = await localStorage.getItem('jwttoken');
        await fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/Simulator/preReqMappingTemplate`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download    
                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Pre-Requisite Sample.xlsx`);
                // Append to html link element page    
                document.body.appendChild(link); // Start download    
                link.click();// Clean up and remove the link    
                link.parentNode.removeChild(link);
            })
    }

    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
            <TestCaseHeader
                title="Upload Pre-Requisite Data File"
                showTestCustomer={{
                    show: true,
                }}
            />

            <Box container sx={{ height: 200, width: 600 }} margin={3}>
                <Dragger style={{ padding: '30px', cursor: 'pointer', width: 600, }}
                    name="file"
                    multiple={false}
                    // beforeUpload={file => {
                    //     handleFileChange(file);
                    //     return false;
                    // }}
                    beforeUpload={file => {
                        const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
                        const fileType = file.type;

                        if (allowedTypes.includes(fileType)) {
                            handleFileChange(file);
                        } else {
                            // Display an error message or perform any desired action for unsupported file types.
                            console.log('Unsupported file type');
                            Snackbar.displayMsg(`Unsupported File Type`, ALERT_SEVERITY.ERROR);
                        }

                        return false;
                    }}
                    showUploadList={{
                        showRemoveIcon: true,
                        removeIcon: <DeleteOutlined onClick={handleDelete} />,
                    }}
                    maxCount={1}
                    fileList={fileList}
                >
                    <CloudUploadIcon style={{ fontSize: 38, color: '#808080' }} />
                    <p>Drag & Drop your file here</p>
                </Dragger>
            </Box>
            <Box sx={{ display: "flex" }} px={"2%"} mt={6}>
                <MUIButton
                    startIcon={<UploadIcon style={{ fontSize: 18 }} />}
                    title="Upload File"
                    sx={{ mx: 2 }}
                    onClick={handleUpload}
                />
                <MUIButton
                    startIcon={<FileDownloadIcon style={{ fontSize: 18 }} />}
                    title="Download Template"
                    sx={{ mx: 2 }}
                    onClick={handleDownload}
                />
            </Box>
            <Modal
                // title="Upload Status"
                open={modalVisible}
                footer={[]}
            // destroyOnClose
            // onOk={closeModal}
            // onCancel={closeModal}
            >
                <Box>
                    <TestCaseHeader
                        title="Upload Status"
                    />
                    <Box mx={2}>
                        <Typography style={{ fontSize: 18, color: "#006644", fontWeight: "bold" }}>{modalContent}</Typography>
                        <Typography>Total Records:{totalRecords}</Typography>
                        <Typography>Recently Added Records:{recAddedRecords}</Typography>
                        <Typography>Updated Records:{updatedRecords}</Typography>
                    </Box>
                </Box>

            </Modal>
        </Box>
    )
}

export default UploadPreRequisite;
