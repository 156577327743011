import * as yup from 'yup';

export const numberValidationSchema = yup.object().shape({
    transactionCCTDA: yup
        .number()
        .min(100000, 'Number must be at least 100,000')
        .max(500000, 'Number cannot exceed 500,000')
        .required('Number is required'),
    transactionCCTDATCH: yup
        .number()
        .min(100000, 'Number must be at least 100,000')
        .max(1000000, 'Number cannot exceed 1,000,000')
        .required('Number is required'),
    // transactionCCTCA: yup
    //     .number()
    //     .min(100000, 'Number must be at least 100,000')
    //     .max(500000, 'Number cannot exceed 500,000')
    //     .required('Number is required'),
    // responseTimeCCTDA: yup.string().required('Debitor agent is required'),
    // responseTimeCCTCA: yup.string().required('Creditor FI is required'),
    // responseTimeCCTFNS: yup
    //     .string()
    //     .required('FDN is required'),

    // responseTimeCCTDA: yup.number()
    //     .typeError('Must be a Number')
    //     .min(1, 'Sec must be at least 1')
    //     .max(20, 'Total should not be exceed 20 sec')
    //     .test(
    //         'sumValidation',
    //         'Total should not be exceed 20 sec',
    //         function () {
    //             const { responseTimeCCTCA, responseTimeCCTFNS } = this.parent;
    //             const sum = Number(this.parent.responseTimeCCTDA) + Number(responseTimeCCTCA) + Number(responseTimeCCTFNS);
    //             return sum <= 20;
    //         }
    //     ).required('Sec is required'),
    responseTimeCCTCA: yup.number()
        // .typeError(' Must be a number')
        .required('Response time (in seconds) is required')
        .min(1, 'Sec must be at least 1')
        .max(5, 'Sec should not be exceed 5 sec')
        .integer("Sec should not be an decimal"),
    // responseTimeCCTFNS: yup.number()
    //     .typeError(' Must be a number')
    //     .min(1, 'Sec must be at least 1')
    //     .max(20, 'Total should not be exceed 20 sec')
    //     .test(
    //         'sumValidation',
    //         'Total should not be exceed 20 sec',
    //         function () {
    //             const { responseTimeCCTDA, responseTimeCCTCA } = this.parent;
    //             const sum = Number(responseTimeCCTDA) + Number(responseTimeCCTCA) + Number(this.parent.responseTimeCCTFNS);
    //             return sum <= 20;
    //         }
    //     ),

    // .test('time-gap', 'FDN rejected the message due to excessive gap', 
    // function (value) {
    //     const responseTime = parseInt(this.parent.timeCA);
    //     const debitorAgent = parseInt(this.parent.timeDA);
    //     const gap = responseTime - debitorAgent;
    //     return gap <= 15;
    // }),
});

export const organizationValidationSchema = yup.object().shape({
    organizationType:yup.number().required("Organization Type is required"),
    configSettlement:yup.string().required("Settlement Simulato Type is required"),
    configPayment:yup.number().required("Payment Gateway Type is required")
})