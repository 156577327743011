import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import React, { useContext } from "react";
import './dashBoard.css'
import ChartContainer from "./chartContainer";
import { useGetTestExecutionTrendMutation } from "../../redux/dashboard/dashboard.api";
import moment from 'moment';
import PayaptLoader from "../../shared/components/Spinner";
import { DropdownValueContext } from "../../shared/context/DropdownProvider";
import { useSelector } from "react-redux";

const ChartHeader = (props) => {
  const { messageName } = props;
  const { value, customer } = useContext(DropdownValueContext);
  const [selectedType, setSelectedType] = React.useState('SA');
  const [dateRange, setDateRange] = React.useState('7');
  const { userRole } = useSelector((state) => state?.common?.userDetails);
  const { customerId } = useSelector((state) => state?.common);

  const [getTestExecutionTrend, { data, isLoading, isSuccess, isError }] = useGetTestExecutionTrendMutation();

  React.useEffect(() => {
    const payload = {
      // testType: selectedType,
      fromDate: moment.utc().startOf('day').subtract(Number(dateRange), 'day'),
      endDate: moment.utc().startOf('day'),
      customerId: (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer,
      messageType: messageName ? messageName : null,
      paymentServiceId: value
    }
    if (customer || customerId) {
      getTestExecutionTrend(payload);
    }
  }, [dateRange, customer, messageName, value])
  const handleChange = (event) => {
    setSelectedType(event.target.value);
  };
  const handleDateChange = (event) => {
    setDateRange(event.target.value);
  };
  const selectFieldStyles = {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF",
      borderWidth: "0px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF",
      borderWidth: "0px",
    },
    height: 40,
    backgroundColor: 'white'
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box gap={2} sx={{ display: 'flex', alignItems: 'center', height: '30px', justifyContent: 'space-between' }}>
        <Typography className="chart-header-text">Test Execution trend</Typography>
        <Box>
          {/* <FormControl sx={{ m: 1, width: 200 }}>
          <Select
          className="dropdown-chart"
      sx={selectFieldStyles}
            value={selectedType}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={'SA'}>Standalone Message</MenuItem>
            <MenuItem value={'MF'}>Message Flows</MenuItem>
          </Select>
              </FormControl> */}
          <FormControl sx={{ m: 1, width: 200 }}>
            <Select
              sx={selectFieldStyles}
              value={dateRange}
              onChange={handleDateChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >

              <MenuItem value={7}>Last 7 Days</MenuItem>
              <MenuItem value={30}>Last 30 Days</MenuItem>
              <MenuItem value={60}>Last 60 Days</MenuItem>
              <MenuItem value={180}>Last 180 Days</MenuItem>
              <MenuItem value={360}>Last 360 Days</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      {isLoading && <PayaptLoader />}
      <ChartContainer data={data || {}} dateRange={dateRange} />
    </Box>
  )
}
export default ChartHeader