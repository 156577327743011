import React, { useContext, useState } from 'react';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate, useLocation } from 'react-router-dom';
import PayaptTextField from '../../shared/components/TextField';
import { signupValidationSchema } from './utils';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateCustomerMutation } from '../../redux/registration/registration.api';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MUIButton from '../../shared/components/MUIButton';
import { LeftCircleFilled } from '@ant-design/icons';
import { SnackbarContext } from "../../shared/context/SnackbarContext.Context";
import { ALERT_SEVERITY } from "../../shared/constants/constants";
import { SignUpMsg } from '../../shared/constants/AlertMessages';
// import { useRequestOtpMutation } from "../../redux/registration/registration.api";
import { updateSignUpDetails } from '../../redux/common/common.slice';
import { useDispatch } from 'react-redux';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PhoneInput from 'react-phone-input-2';
import Dropdown from '../../shared/components/Dropdown';


const Signup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const snackbar = useContext(SnackbarContext);
    const resolver = yupResolver(signupValidationSchema);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };
    const {
        control,
        register,
        setValue,
        clearErrors,
        reset,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver
    });
    const handleValidate = (value) => {
        const isValid = isValidPhoneNumber(value);
        return isValid;
    }
    const [createCustomer, { loading }] = useCreateCustomerMutation();
    // const [requestOtp, { data, isError, isSuccess }] = useRequestOtpMutation();

    const handleSaveNavigation = async (xRes) => {
        console.log("xRes",xRes);
        if (xRes?.error?.status === 400) {
            snackbar.displayMsg(`${xRes?.error?.data?.message}`, ALERT_SEVERITY.ERROR);
        } else {
            dispatch(updateSignUpDetails(xRes?.data.customerId))
            snackbar.displayMsg(SignUpMsg.SIGN_UP_SUCCESS, ALERT_SEVERITY.SUCCESS);
            navigate('/signin');
        }
        // if (xRes?.data.customerId) {
        //     dispatch(updateSignUpDetails(xRes?.data.customerId))
        //     snackbar.displayMsg(SignUpMsg.SIGN_UP_SUCCESS, ALERT_SEVERITY.SUCCESS);
        //     navigate('/signin');
        //     // navigate('/requestotp', { state: { email: xRes?.data.email, fromPath: location?.pathname } })
        // } else if(xRes?.error?.data?.message === "Email ID already exists") {
        //     snackbar.displayMsg("Email ID already exists", ALERT_SEVERITY.ERROR);
        // }
    }

    const onSignup = async (data) => {
        console.log("SignUp", data);
        const userDetials = await createCustomer(data);
        // console.log("userdeatial", userDetials);
        handleSaveNavigation(userDetials);
    }
    const backLogin = () => {
        navigate('/signin');
    }
    //TODO: use label prop available in the <PayaptTextField label={""}/>  instead of writing the individual spans
    return (
        <Grid columns={1}>
            <Grid container mt={2} mb={2} px={1.5} py={2} sx={{ textAlign: 'left' }}>
            <Grid lg={12} md={12} sm={12} xs={12} item
                    className={!!errors['paymentServiceId'] ? 'login-grid-item-error' : 'login-grid-item'}>
                    <span style={{ textAlign: 'left', lineHeight: 2.5 }}>Payment Service<font color='red'>*</font></span>
                    {/* <br /> */}
                    <Controller
                        control={control}
                        name={"paymentServiceId"}
                        render={({ field }) => (
                            <Dropdown
                            {...register('paymentServiceId')}
                            {...field}
                            placeholder='Select Payment Service'
                            margin="dense"
                            sx={{ mt: 1 }}
                            className="user-dropdown"
                            style={{ width: '100%', height: '56px' }}
                            inputProps={{ 'aria-label': 'Without label' }}
                            // options={customerTypes}
                            error={!!errors['paymentServiceId']}
                            helperText={errors['paymentServiceId']?.message}
                        />
                        )}
                    />
                    {errors["paymentServiceId"] && (
                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Payment Service is required</div>
                    )}
                    {/* <br /> */}
                </Grid>
                <br />
                <Grid lg={12} md={12} sm={12} xs={12} item
                    className={!!errors['customerOrganizationName'] ? 'login-grid-item-error' : 'login-grid-item'}>
                    <span style={{ textAlign: 'left', lineHeight: 2.5 }}>Organization Name <font color='red'>*</font></span>
                    {/* <br /> */}
                    <Controller
                        control={control}
                        name={"customerOrganizationName"}
                        render={({ field }) => (
                            <PayaptTextField
                                {...register("customerOrganizationName")}
                                {...field}
                                id="organizationNameInput"
                                placeholder='Enter your organization name' className='signin-text-box'
                                error={!!errors['customerOrganizationName']}
                                helperText={errors['customerOrganizationName']?.message}
                            />
                        )}
                    />
                    {/* <br /> */}
                </Grid>
                <Grid lg={12} md={12} sm={12} xs={12} item
                    className={!!errors['firstName'] ? 'login-grid-item-error' : 'login-grid-item'}>
                    <span style={{ lineHeight: 2.5 }}>First Name <font color='#FF0000'>*</font></span>
                    {/* <br /> */}
                    <Controller
                        control={control}
                        name={"firstName"}
                        render={({ field }) => (
                            <PayaptTextField
                                {...register("firstName")}
                                {...field}
                                placeholder='Enter your First Name'
                                className='signin-text-box'
                                id={'firstName'}
                                type='text'
                                error={!!errors['firstName']}
                                helperText={errors['firstName']?.message}
                                inputProps={{ className: 'signin-input-box' }}
                            />
                        )}
                    />
                    <br />
                </Grid>
                <Grid lg={12} md={12} sm={12} xs={12} item
                    className={!!errors['lastName'] ? 'login-grid-item-error' : 'login-grid-item'}>
                    <span style={{ lineHeight: 2.5 }}>Last Name <font color='#FF0000'>*</font></span>
                    {/* <br /> */}
                    <Controller
                        control={control}
                        name={"lastName"}
                        render={({ field }) => (
                            <PayaptTextField
                                {...register("lastName")}
                                {...field}
                                placeholder='Enter your Last Name'
                                className='signin-text-box'
                                id={'lastName'}
                                type='text'
                                error={!!errors['lastName']}
                                helperText={errors['lastName']?.message}
                                inputProps={{ className: 'signin-input-box' }}
                            />
                        )}
                    />
                    <br />
                </Grid>
                <Grid lg={12} md={12} sm={12} xs={12} item
                    className={!!errors['email'] ? 'login-grid-item-error' : 'login-grid-item'}>
                    <span style={{ lineHeight: 2.5 }}>Email Address <font color='#FF0000'>*</font></span>
                    {/* <br /> */}
                    <Controller
                        control={control}
                        name={"email"}
                        render={({ field }) => (
                            <PayaptTextField
                                {...register("email")}
                                {...field}
                                placeholder='Enter your Email Address'
                                className='signin-text-box'
                                id={'email'}
                                type='text'
                                error={!!errors['email']}
                                helperText={errors['email']?.message}
                                inputProps={{ className: 'signin-input-box' }}
                            />
                        )}
                    />
                    <br />
                </Grid>
                <Grid lg={12} md={12} sm={12} xs={12} item
                    className={!!errors['mobile'] ? 'login-grid-item-error' : 'login-grid-item'}>
                    <span style={{ lineHeight: 2.5 }}>Contact Number <font color='#FF0000'>*</font></span>
                    {/* <br /> */}
                    {/* <Controller
                        control={control}
                        name={"mobile"}
                        render={({ field }) => (
                            <PayaptTextField
                                {...register("mobile")}
                                {...field}
                                placeholder='Enter your Contact Number'
                                id='mobile'
                                className='signin-text-box'
                                type='mobile'
                                error={!!errors['mobile']}
                                helperText={errors['mobile']?.message}
                                inputProps={{ className: 'signin-input-box' }}
                            />
                        )}
                    /> */}
                    <Controller
                        name="mobile"
                        control={control}
                        rules={{
                            validate: (value) => handleValidate(value)
                        }}
                        render={({ field: { onChange, value } }) => (
                            <PhoneInput
                                value={value}
                                onChange={onChange}
                                id="mobile"
                                name="mobile"
                                placeholder='Enter Contact Number'
                                margin="dense"
                                addInternationalOption={true}
                                international={true}
                                style={errors.mobile && {
                                    border: '1px solid red'
                                }}
                                inputProps={{

                                    inputRef: register('mobile'),
                                }}
                            />
                        )}
                    />
                    {errors["mobile"] && (
                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>Contact Number is required</div>
                    )}
                    {/* <br /> */}
                </Grid>
                <Grid lg={12} md={12} sm={12} xs={12} item
                    className={!!errors['password'] ? 'login-grid-item-error' : 'login-grid-item'}>
                    <span style={{ lineHeight: 2.5 }}>Password <font color='#FF0000'>*</font></span>
                    {/* <br /> */}
                    <Controller
                        control={control}
                        name={"password"}
                        render={({ field }) => (
                            <PayaptTextField
                                {...register("password")}
                                {...field}
                                placeholder='Enter your Password'
                                className='signin-text-box'
                                id={'password'}
                                // type='password'
                                type={showPassword ? 'text' : 'password'}
                                error={!!errors['password']}
                                helperText={errors['password']?.message}
                                inputProps={{
                                    className: 'signin-input-box',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                    <br />
                </Grid>
                <Grid lg={12} md={12} sm={12} xs={12} item
                    className={!!errors['confirmPassword'] ? 'login-grid-item-error' : 'login-grid-item'}>
                    <span style={{ lineHeight: 2.5 }}>Confirm Password <font color='#FF0000'>*</font></span>
                    {/* <br /> */}
                    <Controller
                        control={control}
                        name={"confirmPassword"}
                        render={({ field }) => (
                            <PayaptTextField
                                {...register("confirmPassword")}
                                {...field}
                                placeholder='Enter your Password Again'
                                className='signin-text-box'
                                id={'confirmPassword'}
                                // type='password'
                                type={showPassword2 ? 'text' : 'password'}
                                error={!!errors['confirmPassword']}
                                helperText={errors['confirmPassword']?.message}
                                inputProps={{
                                    className: 'signin-input-box',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword2}
                                                onMouseDown={handleMouseDownPassword2}
                                            >
                                                {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                    <br />
                </Grid>
                <Grid mt={2} mb={4} sx={{ display: 'flex', justifyContent: "space-between" }} className='login-grid-item'>
                    <MUIButton
                        startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                        title="Back to Login"
                        sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                        color="inherit"
                        onClick={backLogin}
                    />
                    <MUIButton
                        startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                        title="Signup"
                        onClick={handleSubmit(onSignup)}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Signup;