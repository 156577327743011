import React, { useEffect } from 'react';
import Dropdown from '../../shared/components/Dropdown';
import { useGetConfigureSettlementSimulatorDropdownQuery } from '../../redux/settings/settings.api';


const styles = {
    menuProps: {
      sx: {
          '.MuiPaper-root': {
              maxHeight: '40%',
          },
        },
    },
}

const data = [
    {
        configureId:1,
        configureName: 'Payapt Simulator'
    },
    {
        configureId: 3,
        configureName: 'Fedline Sandbox'
    },
    {
        configureId: 56789,
        configureName: 'Other Simulator'
    },
    
    
]
const ConfigPartiesDropdown = (props) => {

    const { onChangeParties, value, isClear,} = props;
    const {data:configSettlementList} = useGetConfigureSettlementSimulatorDropdownQuery();

    useEffect(() => {
        if (!_.isEmpty(configSettlementList)) {
            onChangeParties(configSettlementList?.id, configSettlementList?.value)
        }
    }, [configSettlementList])

    const onChange = (xEl) => {
        if(xEl){
          const obj = _.find(configSettlementList, m => m?.id === xEl);
          onChangeParties(xEl, obj?.value)
        } else {
          onChangeParties(xEl, xEl)
        }
        
      }
  return (
    <>
      <Dropdown
                placeholder='Select Settlement Simulator Type'
                margin="dense"
                className="user-dropdown"
                style={{ width: '180%', height: '42px', marginTop: '-20px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                options={configSettlementList && configSettlementList}
                valueKey='value'
                labelKey='value'
                onChange={(e) => onChange(e)}
                value={value}
                MenuProps={styles.menuProps}
                isClear={isClear}
            />
    </>
  )
}

export default ConfigPartiesDropdown
