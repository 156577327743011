import React, { useContext, useEffect, useState } from 'react';
import TestCaseHeader from '../testCaseHeader';
import { Box } from '@mui/material';
import MUIButton from '../../shared/components/MUIButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Input, Modal } from 'antd';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { useSystemMessageRequestMutation, useSystemMessageResponseMutation } from '../../redux/Reports/reports.api';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useSelector } from 'react-redux';
import { ALERT_SEVERITY } from '../../shared/constants/constants';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';

const FednowPartFile = () => {
    const { TextArea } = Input;
    const Snackbar = useContext(SnackbarContext);
    // const [customer, selectedCustomer] = useState();
    const { customerId } = useSelector((state) => state?.common);
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const {  customer } = useContext(DropdownValueContext);
    const [systemRequestGeneration] = useSystemMessageRequestMutation();
    const [systemResponse] = useSystemMessageResponseMutation();
    const [isModalVisible, setModalVisibility] = useState(false);
    const [value, setValue] = useState([]);
    const [rtnRes, setRtnRes] = useState();
    const [admi004RetriveId, setAdmi004RetriveId] = useState();

    const onCancel = () => {
        setModalVisibility(false)
    };

    const downloadFile = () => {
        if (value !== "NO DATA" ) {
            const element = document.createElement("a");
            const file = new Blob([value], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = "Admi.998"
            document.body.appendChild(element);
            element.click();
            setModalVisibility(true) 
        } else {
           Snackbar.displayMsg("No data to download",ALERT_SEVERITY.ERROR); 
        }   
    };

    const systemMessagePayload = {
        messageTypeName:"Participant File",
        boardcastMessageTypeName:null,
        customerId:(userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer,
        rtn: null
    };

    const handleGenerate = async(isVisible) => {
        // if (broadMsg === undefined || rtnNum === undefined ) {
        //    Snackbar.displayMsg("Please Select the Values",ALERT_SEVERITY.ERROR);
        //    return; 
        // } else {
            await systemRequestGeneration(systemMessagePayload).then(res => {
                console.log("Res", res);
                if (res?.data?.status === "success") {
                    Snackbar.displayMsg(res?.data?.message, ALERT_SEVERITY.SUCCESS);
                    setAdmi004RetriveId(res?.data?.admi004RetriveId);
                    setRtnRes(res?.data?.rtn);
                    setTimeout(() => {
                        setModalVisibility(isVisible);
                    }, 5000);
                } else {
                    Snackbar.displayMsg("Failed to Generated System Messages", ALERT_SEVERITY.ERROR);  
                }
            })
           
        // }
        
    };

    const responsePayload = {
        messageTypeName:"Participant File",
        boardcastMessageTypeName:
        null,
        customerId:(userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer,
        rtn:rtnRes ? [rtnRes] : null,
        admi004RetriveIds:rtnRes ? null : admi004RetriveId
    }
    

    // useEffect(() => {
    //     if (isModalVisible !==false) {
    //        systemResponse(responsePayload).then(res => {
    //         console.log("responsePayloadCCC2",res);
    //         setValue(res?.data?.data[0]?.responseXML)
    //        }) 
    //     }
    // },[isModalVisible])

    useEffect(() => {
        if (isModalVisible !== false) {
            systemResponse(responsePayload).then(res => {
                console.log("responsePayloadCCC2", res);
                const responseData = res?.data?.data;
                if (responseData && responseData.length > 0) {
                    setValue(responseData[0]?.responseXML || "NO DATA");
                } else {
                    setValue("NO DATA");
                }
            });
        }
    }, [isModalVisible]);
    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
            <TestCaseHeader
                title="Fednow Participant File (Admi.998)"
                showTestCustomer={{
                    show: true,
                    // onChange: onTestCaseCustomerSelection,
                    // value: customer
                }}
                showCustomer={{ show: false,  }}
            />

            <Box mt={14} sx={{ display: "flex", px: '10%' }}>
                <MUIButton
                    startIcon={<CheckCircleIcon style={{ fontSize: 18, }} />}
                    title="Generate Fednow Participant File"
                    onClick={handleGenerate}
                />
            </Box>

            <Modal
                onCancel={onCancel}
                open={isModalVisible} footer={[]}
                closeIcon={<CancelOutlinedIcon style={{ color: "#001A11", backgroundColor: "white" }} />}
                destroyOnClose
                width={"70%"}
                maskClosable={false}
            >
                <Box>
                    <TestCaseHeader 
                    title={"Admi.998"}

                    showFileUpload={{
                        show: true,
                        customIcon: <FileDownloadIcon />,
                        onClick: () => downloadFile()
                    }}
                    // showRefresh={{
                    //     show: false,
                    //     onClick: handleRefreshButton
                    // }}
                    />
                    {/* <WrapperComponent isSuccess={isSuccess} isFetching={isLoading}> */}
                    <div style={{ marginTop: 5 }}>

                        <TextArea
                            className="xml-txt-area"
                            rows={15}
                            readOnly
                            style={{ height: '100%' }}
                        // value={value.length > 0 ? value[startIndex]?.response : 'IN PROGRESS'} // Display the response based on the current page
                            value={value}
                        />


                        {/* <div style={{ marginTop: 4, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                            
                            <Pagination
                                current={currentPage}
                                pageSize={itemsPerPage}
                                total={value?.length}
                                onChange={changePage}
                                hideOnSinglePage={true}
                            />
                           
                        </div> */}

                    </div>

                </Box>

            </Modal>
        </Box>
    )
}

export default FednowPartFile;
