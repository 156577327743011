import { Checkbox, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { useDrag } from 'react-dnd'
import { ExpandIcon } from '../../assets/base64/images';
import TestCaseList from '../../screens/testcaseList';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const DraggableTestcase = (props) => {
    const { item, index, className, onClick, actions, showExpandIcon, title,
      isDraggable=false, onTestcaseSelectionChange, showSelection=false, isSelected, onSelectionChanged,
      onSubItemSelect, selectedItems } = props;
    const [expanded, setExpanded ] = useState(false);
    const [{ opacity }, dragRef] = useDrag(
        () => ({
          type: 'TC',
          item: { ...item },
          collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.5 : 1,
          })
        }),
        [item]
      )
      const styles = {
        
        cardStyle: {
            width: '100%',
            px: 2,
            backgroundColor: "rgba(0,0,0, 0.05)",
            my: 2,
            py: 2,
            borderRadius: '4px'
        },
        contentContainer: {
          // p: 2
          mt: 1,
          maxHeight: "50vh",
          overflowY: "auto"
      }
    }

    useEffect(() => {
      setExpanded(false);
    }, [item])
    return (
          <Grid key={index}
          className={className}
          onClick={onClick}
          style={{ margin: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          ref={isDraggable ? dragRef : null}
          sx = {isDraggable ? {cursor: 'move'} : {cursor: 'pointer'}}
        >
          <div style = {{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <div style = {{display: 'flex', flexDirection: 'row', width: _.isEmpty(actions) ? '100%' : '85%', alignItems: 'center'}}>
          { isDraggable && <DragIndicatorIcon sx = {{color: '#ACACAC'}}/>}
          {showExpandIcon && 
          <div style = {{ marginRight: 5}} onClick = {() => setExpanded(!expanded)}>
            { !expanded ? <ExpandIcon />: 
            <IndeterminateCheckBoxIcon size = 'small' sx={{ fontSize:20, color: '#ACACAC' }}/>}
          </div>}
          {showSelection && 
          <div onClick={e => e.stopPropagation()}><Checkbox sx={{padding: 0, marginRight: 1}} checked = {isSelected} size = 'small' 
          onChange={(e) => {
            onSelectionChanged(e, item)
          }}/>
          </div>
          }
          <Typography noWrap = {true}>
          {title || item.title} 
          </Typography>
          </div>
          <div style = {{ width: '15%', display: 'flex', flexDirection: 'row', justifyContent:'space-between'}}>
          {
            _.map(actions, (ac,index) => <span onClick={(e) => ac.onClick(e, item)} className='action-item' key={index}>{ac.icon}</span>)
          }
          </div>
          </div>
          {
            expanded && <TestCaseList onChange ={onSubItemSelect} testDataDetails = {item?.testDataDetails} selectedItems = {selectedItems}/>
          }
         
      </Grid>
    )
    
}

export default DraggableTestcase;
