import { Box, Card, CardContent, Grid, List, ListItem, Typography } from "@mui/material";
import { Modal, Upload } from "antd";
import React, { useContext, useEffect, useState } from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DeleteOutlined } from '@ant-design/icons';
import { LeftCircleFilled } from '@ant-design/icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MUIButton from "../../shared/components/MUIButton";
import TestCaseHeader from "../testCaseHeader";
// import { useUploadTestCaseDataMutation } from "../../redux/uploadTestcase/uploadTestcase.api";
import InboundOutboundDropdown from "../onboarding/InboundOutboundDropdown";
import MessageTypeDropdown from "../Dashboard/MessageTypeDropdown";
import { SnackbarContext } from "../../shared/context/SnackbarContext.Context";
import { ALERT_SEVERITY, APP_ROUTE_KEYS } from "../../shared/constants/constants";
import PayaptLoader from '../../shared/components/Spinner';
import { useNavigate } from "react-router-dom";
import UploadMessageDropdown from "./UploadMessageDropdown";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useSaveTestcaseforCustomerMutation } from "../../redux/uploadTestcase/uploadTestcase.api";
import { useSelector } from "react-redux";
import { DropdownValueContext } from "../../shared/context/DropdownProvider";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UploadIcon from '@mui/icons-material/Upload';

const { Dragger } = Upload;
const styles = {
    uploadButtonContainer: {
        textTransform: 'none',
        fontSize: "16px",
        fontFamily: "Inter",
        fontWeight: 600,
        backgroundColor: 'transparent',
        color: '#009966',
        borderColor: "#009966",
        border: ' 1px solid #009966',
        height: 40
    },
    draggerContainer: {
        minHeight: '35vh',
        maxHeight: '50vh',
        minWidth: "60%",
        borderRadius: '20px'
    },
    floatingActionFlexFiveContainer: {
        flex: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'
    }
}
const UploadTestCase = () => {
    const Snackbar = useContext(SnackbarContext);
    const { value, customer } = useContext(DropdownValueContext);
    const navigate = useNavigate();
    const { customerId } = useSelector((state) => state?.common);
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFile2, setSelectedFile2] = useState(null);
    const [selectedFile3, setSelectedFile3] = useState(null);
    const [isDragdropModalVisible, setIsDragdropModalVisible] = React.useState(false);
    const [fileList, setFileList] = useState([]);
    const [file2List, setFile2List] = useState([]);
    const [file3List, setFile3List] = useState([]);
    const [messageType, SetMessgaeType] = useState('Message Type');
    const [inboundOrOutboundValue, setInboundOrOutboundValue] = useState();
    const [messagePack, setMessagePack] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setModalVisibility] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [totalRecords, setTotalRecords] = useState(null);
    const [recAddedRecords, setRecAddedRecords] = useState(null);
    const [updatedRecords, setUpdatedRecords] = useState(null);
    const [preReqMappingMissing, setPreReqMappingMissing] = useState(null);
    const [parentTestCaseMissing, setParentTestCaseMissing] = useState(null);
    const [fileData, setFileData] = useState();
    const formData = new FormData();
    const token = localStorage.getItem('jwttoken');
    const [updateCustomerTestCase, { data }] = useSaveTestcaseforCustomerMutation();


    const handleModalVisible = (isVisible) => {
        setModalVisibility(isVisible)
    }

    const handleDelete = () => {
        setSelectedFile(null);
        setFileList([]);
    };
    const handleDeleteFile2 = () => {
        setSelectedFile2(null);
        setFile2List([]);
    };
    const handleDeleteFile3 = () => {
        setSelectedFile3(null);
        setFile3List([]);
    };
    const handleCancel = () => {
        // navigate(`${APP_ROUTE_KEYS.TEST_CASES_REPO.MASTER}`)
        setIsDragdropModalVisible(false);
        setSelectedFile(null);
        setFileList([]);
        SetMessgaeType(null);
        setInboundOrOutboundValue(null);
        setSelectedFile2(null);
        setFile2List([]);
        setSelectedFile3(null);
        setFile3List([]);
    };
    const handleFileChange = (file) => {
        setSelectedFile(file);
        setFileList([
            {
                uid: file.uid,
                name: file.name,
                status: 'done',
                url: URL.createObjectURL(file),
            },
        ]);
    };
    const handleFile2Change = (file) => {
        setSelectedFile2(file);
        setFile2List([
            {
                uid: file.uid,
                name: file.name,
                status: 'done',
                url: URL.createObjectURL(file),
            },
        ]);
    };
    const handleFile3Change = (file) => {
        setSelectedFile3(file);
        setFile3List([
            {
                uid: file.uid,
                name: file.name,
                status: 'done',
                url: URL.createObjectURL(file),
            },
        ]);
    };
    // useEffect(() => {
    //     formData.append('message', JSON.stringify(inboundOrOutboundValue))
    //     formData.append('file', selectedFile)
    //     formData.append('file2', selectedFile2)
    //     formData.append('direction', JSON.stringify(messagePack))
    // }, [inboundOrOutboundValue, selectedFile, selectedFile2, messagePack])
    // console.log('formdata', formData);


    const handleUpload = (file) => {
        console.log('file', file);
        formData.append('message', messageType)
        formData.append('customerId', (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer)
        formData.append('paymentServiceId', value)
        if (selectedFile) {
            formData.append('testcaseMF', selectedFile)
        }
        if (selectedFile2) {
            formData.append('testDataMF', selectedFile2)
        }
        if (selectedFile3) {
            formData.append('testDataSA', selectedFile3)
        }
        formData.append('direction', inboundOrOutboundValue)
        for (const entry of formData.entries()) {
            console.log("formdata", entry);
        }

        // console.log('formdataa', formData);
        uploadTestCaseData(formData)
    }
    const onMessageSelection = (data, data2) => {
        console.log('msgtype', data);
        SetMessgaeType(data)
        setMessagePack(data2)
    }
    const onChangeInboundOrOutbound = (event) => {


        console.log("selected value", event);

        setInboundOrOutboundValue(event);
    }
    const uploadTestCaseData = () => {
        setIsLoading(true)
        // formData.append('file', selectedFile);

        // Make the API request with the form data 
        //Simulator/uploadTestCaseDataLatest
        fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/TestCaseUpload/SaveTestCaseUpload`, {
            method: 'POST',
            body: formData,
            // mode:'no-cors',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => response.json())
            .then(res => {
                console.log('response', res);
                if (res.status == "success") {
                    setIsLoading(false)
                    // console.log('response-if', response?.status);
                    // message.success(`${selectedFile.name} file uploaded successfully.`);
                    // Snackbar.displayMsg(`File Uploaded Successfully.`, ALERT_SEVERITY.SUCCESS);
                    setModalContent('File Uploaded Successfully');
                    setTotalRecords(res.totalRecords);
                    setRecAddedRecords(res.recentlyAddedRecords);
                    setUpdatedRecords(res.updatedRecords);
                    setPreReqMappingMissing(res?.preReqMappingMissing);
                    setParentTestCaseMissing(res?.parentTestCaseMissing);
                    setFileData(res?.fileData);
                    handleModalVisible(true)
                    setSelectedFile(null);
                    setSelectedFile2(null);
                    setSelectedFile3(null);
                    SetMessgaeType(null);
                    setInboundOrOutboundValue(null);
                    setFileList([])
                    setFile2List([])
                    setFile3List([])
                    formData.delete('message')
                    formData.delete('testcaseMF')
                    formData.delete('testDataMF')
                    formData.delete('testDataSA')
                    formData.delete('direction')
                    // window.location.reload();
                }
                else if (res.debugMessage == "empty sheet") {
                    setIsLoading(false)
                    Snackbar.displayMsg("File is Empty Upload Again", ALERT_SEVERITY.ERROR);
                }
                else {
                    // message.error(`${selectedFile.name} file upload failed.`);
                    setIsLoading(false)
                    Snackbar.displayMsg(res?.message, ALERT_SEVERITY.ERROR);

                }
            })
            .catch(error => {
                // Handle any network errors
                console.error('Upload error:', error);
                Snackbar.displayMsg(`File Upload Failed.`, ALERT_SEVERITY.ERROR);
            });
        setSelectedFile(null)
        setSelectedFile2(null)
        setSelectedFile3(null)
        setFileList([])
        setFile2List([])
        setFile3List([])
        formData.delete('message')
        formData.delete('testcaseMF')
        formData.delete('testDataMF')
        formData.delete('testDataSA')
        formData.delete('direction')
    }

    const handleUploadCustomer = () => {
        const formDataForUploadCustomer = new FormData()
        setIsLoading(true)
        formDataForUploadCustomer.append('customerId', (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer)
        formDataForUploadCustomer.append('paymentServiceId', value)

        // const payload = {
        //     customerId : customerr ? customerr : customerId,
        //     paymentServiceId:value
        // }
        fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/Simulator/SaveTestcaseforCustomer`, {
            method: 'POST',
            body: formDataForUploadCustomer,
            // mode:'no-cors',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(res => {
                // console.log("resSSS",res);
                handleModalVisible(false)
                if (res?.status === 200) {
                    setIsLoading(false)
                    Snackbar.displayMsg("Based on Customer Test Cases Uploaded Successfully", ALERT_SEVERITY.SUCCESS)
                } else {
                    Snackbar.displayMsg("Based on Customer Test Cases Failed to Upload", ALERT_SEVERITY.ERROR)
                }
            })

    }


    const handleDownload = () => {
        // Your example base64 string, truncated for the example
        const base64String = fileData 

        try {
            // Ensure the base64 string is properly formatted
            const formattedBase64String = base64String.replace(/[\n\r\s]+/g, '');
            const byteCharacters = atob(formattedBase64String);

            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // Convert the byte array to a Blob
            const blob = new Blob([byteArray], { type: "text/plain" });

            // Create a URL for the Blob and initiate download
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Unsuccessful Uploads.txt'; // Adjust the file name as needed
            document.body.appendChild(a); // Append to the document body temporarily
            a.click();

            // Cleanup
            URL.revokeObjectURL(url);
            a.remove(); // Remove the anchor from the body
        } catch (error) {
            console.error('Error decoding base64 string:', error);
        }
    };
    const handleDownloadTestCase = async() => {
        const token = await localStorage.getItem('jwttoken');
        await fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/Simulator/messageFlowTestcaseUploadTemplate`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download    
                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `TestCase MF Sample.xlsx`);
                // Append to html link element page    
                document.body.appendChild(link); // Start download    
                link.click();// Clean up and remove the link    
                link.parentNode.removeChild(link);
            })
    };

    const handleDownloadTestData = async() => {
        const token = await localStorage.getItem('jwttoken');
        await fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/Simulator/messageFlowTestDataUploadTemplate`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download    
                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `TestData MF Sample.xlsx`);
                // Append to html link element page    
                document.body.appendChild(link); // Start download    
                link.click();// Clean up and remove the link    
                link.parentNode.removeChild(link);
            })
    };

    const handleDownloadSA = async() => {
        const token = await localStorage.getItem('jwttoken');
        await fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/Simulator/standaloneTestcaseUploadTemplate`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download    
                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `TestData & TestCase SA Sample.xlsx`);
                // Append to html link element page    
                document.body.appendChild(link); // Start download    
                link.click();// Clean up and remove the link    
                link.parentNode.removeChild(link);
            })
    };

    const handleDownloadCases = async() => {
       await handleDownloadTestCase();
       await handleDownloadTestData();
       await handleDownloadSA();
    }

    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
            <TestCaseHeader
                title="Upload Test Cases"
                showTestCustomer={{
                    show: true,
                    // onChange: onTestCaseCustomerSelection,
                    // value: customer
                }}
            // showInboundOutboundUpload={{
            //     show: true,
            //     onChange: onInboundOrOutboundChange,
            //     value: inboundOrOutboundValue
            // }}
            // //   displayInLine={true}
            // showMessageType={{ show: true, onChange: onMessageSelection, value: messageType, isClear: true }}
            />
            {isLoading && <PayaptLoader />}
            <Box sx={{ padding: '2%' }}>
                <Box>
                    <Grid container>
                        <Grid item mt={2.5} width={'22%'}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "16px" }}>
                                Message Type
                            </Typography>
                        </Grid>
                        <Grid item mt={4.5} width={210} mx={'20%'}>
                            {/* <InboundOutboundDropdown onChangeInboundOrOutbound={onChangeInboundOrOutbound} value={inboundOrOutboundValue}/> */}
                            {/* <MessageTypeDropdown onChangeMessage={onMessageSelection} value={messageType} isClear={true} /> */}
                            <UploadMessageDropdown onChangeMessage={onMessageSelection} value={messageType} />
                        </Grid>
                    </Grid>

                </Box>

                <Box>
                    <Grid container>
                        <Grid item mt={2.5} width={'22%'}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "16px" }}>
                                Direction
                            </Typography>
                        </Grid>
                        <Grid item mt={4.5} mx={'20%'} width={210}>
                            <InboundOutboundDropdown onChangeInboundOrOutbound={onChangeInboundOrOutbound} value={inboundOrOutboundValue} sx={{ backgroundColor: "red" }} />
                        </Grid>
                    </Grid>

                </Box>

                <Box >
                    {/* <Box display={'flex'} sx={{ justifyContent: 'space-around', padding: '50px' }}> */}
                    <Grid container>
                        <Grid item mt={7.5} width={'22%'}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "16px" }}>
                                Test Case MF
                            </Typography>
                            <Typography sx={{ display: "flex", fontStyle: 'normal', fontWeight: 60, fontSize: "14px" }}>
                                (Select test cases file for MF)
                            </Typography>
                        </Grid>
                        <Grid item mx={'20%'} mt={2.5} mb={2.5}>
                            <Dragger style={{ padding: '10px', cursor: 'pointer', width: 300 }}
                                name="file"
                                multiple={false}
                                // beforeUpload={file => {
                                //     handleFileChange(file);
                                //     return false;
                                // }}
                                beforeUpload={file => {
                                    const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
                                    const fileType = file.type;

                                    if (allowedTypes.includes(fileType)) {
                                        handleFileChange(file);
                                    } else {
                                        // Display an error message or perform any desired action for unsupported file types.
                                        console.log('Unsupported file type');
                                        Snackbar.displayMsg(`Unsupported File Type`, ALERT_SEVERITY.ERROR);
                                    }

                                    return false;
                                }}
                                showUploadList={{
                                    showRemoveIcon: true,
                                    removeIcon: <DeleteOutlined onClick={handleDelete} />,
                                }}
                                maxCount={1}
                                fileList={fileList}
                            >
                                <CloudUploadIcon style={{ fontSize: 38, color: '#808080' }} />
                                <p>Drag & Drop your file here</p>
                            </Dragger>
                        </Grid>
                    </Grid>
                    {/* </Box> */}
                </Box>


                <Box >

                    <Grid container>
                        <Grid item mt={7.5} width={'22%'}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "16px", textAlign: 'left' }}>
                                Test Data MF
                            </Typography>
                            <Typography sx={{ display: "flex", fontStyle: 'normal', fontWeight: 60, fontSize: "14px", textAlign: 'left' }}>
                                (Select test data file for MF test cases)
                            </Typography>
                            <Typography sx={{ display: "flex", fontStyle: 'normal', fontWeight: 60, fontSize: "14px", textAlign: 'left' }}>
                                (Test data should not be uploaded separately without/before uploading test case)
                            </Typography>
                        </Grid>
                        <Grid item mx={'20%'} mt={2.5}>
                            <Dragger style={{ padding: '10px', cursor: 'pointer', width: 300, }}
                                name="file"
                                multiple={false}
                                // beforeUpload={file => {
                                //     handleFile2Change(file);
                                //     return false;
                                // }}
                                beforeUpload={file => {
                                    const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
                                    const fileType = file.type;

                                    if (allowedTypes.includes(fileType)) {
                                        handleFile2Change(file);
                                    } else {
                                        // Display an error message or perform any desired action for unsupported file types.
                                        console.log('Unsupported file type');
                                        Snackbar.displayMsg(`Unsupported File Type`, ALERT_SEVERITY.ERROR);
                                    }

                                    return false;
                                }}
                                showUploadList={{
                                    showRemoveIcon: true,
                                    removeIcon: <DeleteOutlined onClick={handleDeleteFile2} />,
                                }}
                                maxCount={1}
                                fileList={file2List}
                            >
                                <CloudUploadIcon style={{ fontSize: 38, color: '#808080' }} />
                                <p>Drag & Drop your file here</p>
                            </Dragger>
                        </Grid>
                    </Grid>



                </Box>


                <Box >

                    <Grid container>
                        <Grid item mt={8} width={'22%'}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "16px", textAlign: 'left' }}>
                                Test Case & Test Data SA
                            </Typography>
                            <Typography sx={{ display: "flex", fontStyle: 'normal', fontWeight: 60, fontSize: "14px", textAlign: 'left' }}>
                                (Select file containing SA test cases and data)
                            </Typography>
                        </Grid>
                        <Grid item mx={'20%'} mt={4}>
                            <Dragger style={{ padding: '10px', cursor: 'pointer', width: 300, }}
                                name="file"
                                multiple={false}
                                // beforeUpload={file => {
                                //     handleFile2Change(file);
                                //     return false;
                                // }}
                                beforeUpload={file => {
                                    const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
                                    const fileType = file.type;

                                    if (allowedTypes.includes(fileType)) {
                                        handleFile3Change(file);
                                    } else {
                                        // Display an error message or perform any desired action for unsupported file types.
                                        console.log('Unsupported file type');
                                        Snackbar.displayMsg(`Unsupported File Type`, ALERT_SEVERITY.ERROR);
                                    }

                                    return false;
                                }}
                                showUploadList={{
                                    showRemoveIcon: true,
                                    removeIcon: <DeleteOutlined onClick={handleDeleteFile3} />,
                                }}
                                maxCount={1}
                                fileList={file3List}
                            >
                                <CloudUploadIcon style={{ fontSize: 38, color: '#808080' }} />
                                <p>Drag & Drop your file here</p>
                            </Dragger>
                        </Grid>
                    </Grid>



                </Box>

                <Box mt={6} sx={{ display: "flex" }} >
                    <MUIButton
                        startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                        title="Cancel"
                        sx={{ backgroundColor: '#F0F2F5', color: '#808080', marginRight: "10px" }}
                        color="inherit"
                        onClick={() => handleCancel()}
                    >
                    </MUIButton>
                    <MUIButton
                        startIcon={<UploadIcon style={{ fontSize: 18, }} />}
                        title="Upload Data"
                        sx={{ margin: "0px 10px" }}
                        // disabled={!selectedFile && !selectedFile2 && !selectedFile3
                        // || !messagePack
                        // }
                        onClick={handleUpload}
                    />
                    <MUIButton
                        startIcon={<FileDownloadIcon style={{ fontSize: 18, }} />}
                        title="Download Template"
                        sx={{ margin: "0px 10px" }}
                        onClick={handleDownloadCases}
                    />
                </Box>
            </Box>
            <Modal
                onCancel={() => handleModalVisible(false)}
                open={isModalVisible}
                footer={[]}
                closeIcon={<CancelOutlinedIcon style={{ color: "#001A11", backgroundColor: "white" }} />}
                destroyOnClose
                width={'75%'}
            >
                <Box>
                    <TestCaseHeader
                        title="Test cases/data are upload successfully in master database. 
                      Do you want to propagate these data for all customers?"
                        showTestCustomer={{
                            show: false,
                        }}
                        showCustomer={{ show: true, }}
                    />
                    {isLoading && <PayaptLoader />}
                    <Box mx={2}>
                        <Typography style={{ fontSize: 18, color: "#006644", fontWeight: "bold" }}>{modalContent}</Typography>
                        <Typography>Total Records:{totalRecords}</Typography>
                        <Typography>Recently Added Records:{recAddedRecords}</Typography>
                        <Typography>Updated Records:{updatedRecords}</Typography>
                        {(preReqMappingMissing || parentTestCaseMissing) && (
                            <>
                                <Typography style={{ fontWeight: "bold", color: "#D65442" }}>
                                    Unsuccessful Uploads:
                                </Typography>
                                <Card variant="outlined">
                                    <CardContent>
                                        {parentTestCaseMissing && parentTestCaseMissing.length > 0 && (
                                            <div style={{ maxHeight: 150, overflow: 'auto' }}>
                                                <Typography>Parent TestCases Missing:</Typography>
                                                <List>
                                                    {parentTestCaseMissing.map((item, index) => (
                                                        <ListItem key={index}>
                                                            <Typography>{item}</Typography>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </div>
                                        )}
                                        {preReqMappingMissing && preReqMappingMissing.length > 0 && (
                                            <div style={{ maxHeight: 150, overflow: 'auto' }}>
                                                <Typography>TestCases with missing pre-requisite mapping:</Typography>
                                                <List>
                                                    {preReqMappingMissing.map((item, index) => (
                                                        <ListItem key={index}>
                                                            <Typography>{item}</Typography>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </div>
                                        )}
                                        {/* If both are missing or empty, show "No Data" */}
                                        {(!preReqMappingMissing || preReqMappingMissing.length === 0) && (!parentTestCaseMissing || parentTestCaseMissing.length === 0) && (
                                            <Typography variant="body1">No Data</Typography>
                                        )}
                                    </CardContent>
                                </Card>
                            </>
                        )}
                    </Box>

                    <Box mx={3} mt={2} sx={{ display: 'flex' }}>
                        <MUIButton
                            startIcon={<CancelIcon style={{ fontSize: 18 }} />}
                            title="No"
                            color="inherit"
                            sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                            onClick={() => handleModalVisible(false)}
                        />
                        <div style={{ marginLeft: '10px' }}> {/* Add some space between buttons */}
                            <MUIButton
                                startIcon={<CheckCircleIcon style={{ fontSize: 20 }} />}
                                title="Yes"
                                onClick={handleUploadCustomer}
                            />
                        </div>
                        {(preReqMappingMissing || parentTestCaseMissing) !== null && (
                            <div style={{ marginLeft: '10px' }}>
                                <MUIButton
                                    startIcon={<DownloadForOfflineIcon style={{ fontSize: 20 }} />}
                                    title="Download File"
                                    onClick={handleDownload}
                                />
                            </div>
                        )}

                    </Box>
                </Box>
            </Modal>
        </Box>
    )

}
export default UploadTestCase;