import { Box, Grid, InputAdornment, ToggleButton, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import TestCaseHeader from '../testCaseHeader';
import PayaptTextField from '../../shared/components/TextField';
import XTwoWayLabelSwitch from '../../shared/components/XTwoWayLabelSwitch';
import './settingStyles.css'
import { useGetConfigrationParametersDetailMutation, useUpdateConfigurationParametersDetailsMutation } from '../../redux/settings/settings.api';
import { useSelector } from 'react-redux';
import MUIButton from '../../shared/components/MUIButton';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { ALERT_SEVERITY, APP_ROUTE_KEYS } from '../../shared/constants/constants';
import { LeftCircleFilled } from '@ant-design/icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';



const EodProcessing = () => {
    const snackbar = useContext(SnackbarContext);
    const navigate = useNavigate();
    const [transactionCCTDA, setTransactionCCTDA] = React.useState();
    const [transactionCCTCA, setTransactionCCTCA] = useState();
    const [responseTimeCCTDA, setResponseTimeCCTDA] = useState();
    const [responseTimeCCTCA, setResponseTimeCCTCA] = useState();
    const [responseTimeCCTFNS, setResponseTimeCCTFNS] = useState();
    const [eodDetailReport, setEodDetailReport] = useState(false);
    const [eodTotalReport, setEodTotalReport] = useState(false);
    const [eodParticipantReport, setEodParticipantReport] = useState(false);
    const [maxMessage, setMaxMessage] = useState();
    const [messageValidateDA, setMessageValidateDA] = useState(false);
    const [messageValidateCA, setMessageValidateCA] = useState(false);
    const [messageValidateFNS, setMessageValidateFNS] = useState(false);
    const customerId = useSelector((state) => state?.common?.customerId);
    const [getConfigrationParametersDetail, { data }] = useGetConfigrationParametersDetailMutation();
    const [updateConfigurationParametersDetails, { data: updateParameters }] = useUpdateConfigurationParametersDetailsMutation();

    const [selectedTime, setSelectedTime] = useState(null);
    const defaultValue = data?.EODProcessing[0]?.EODBODProcessingTime;
    const [eodbodProcessTime, setEodbodProcessTime] = useState(null);
    console.log('Current Local Time:',defaultValue);
    const utcDate = moment.utc(defaultValue).format('HH:mm:ss')
    console.log("utcDate", utcDate)
    const utc = moment.utc(utcDate).toDate();
    console.log("utc", utc)
    const date = moment.utc(defaultValue).local().format('HH:mm:ss');
    const currentUTC = new Date().toUTCString();
    const defaultTime = new Date(eodbodProcessTime).toLocaleString();

    console.log('Current UTC Time:', currentUTC);
    console.log('date:', date);
    // const defaultTime = moment.utc(defaultValue, 'HH:mm:ss');
    console.log('eodbodProcessTime', eodbodProcessTime);
    

    useEffect(() => {
        // const currentUTC = new Date(eodbodProcessTime).toLocaleString();
        // const defaultTime = moment.utc(currentUTC, 'HH:mm:ss');
        // console.log('Current UTC Time:', currentUTC);
        // console.log('Current Local Time:', defaultTime);
        // setEodbodProcessTime(defaultTime)
        const dateTimeString = '2023-06-12T04:33:14';
        // const time = getTimeFromDateTime(dateTimeString);
    }, [eodbodProcessTime])
    const getTimeFromDateTime = (dateTime) => {
        const dateObj = new Date(dateTime);
        const hours = dateObj.getHours().toString().padStart(2, '0');
        const minutes = dateObj.getMinutes().toString().padStart(2, '0');
        const seconds = dateObj.getSeconds().toString().padStart(2, '0');
    
        console.log('getTimeFromDateTime',`${hours}:${minutes}:${seconds}`);
        // setEodbodProcessTime(`${hours}:${minutes}:${seconds}`)
      };


    const handleChange = (e) => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setNum(e.target.value);
        }
    };
    var savePayload = {
        "transactionLimitsCCTDebitorAgent": transactionCCTDA,
        "transactionLimitsCCTCreditorAgent": transactionCCTCA,
        "responseTimeCCTDebitorAgent": responseTimeCCTDA,
        "responseTimeCCTCreditorAgent": responseTimeCCTCA,
        "responseTimeCCTFedNowSimulator": responseTimeCCTFNS,
        "eodbodProcessingTime": eodbodProcessTime,
        "eodActivityDetailReport": eodDetailReport,
        "eodActivityTotalReport": eodTotalReport,
        "eodListOfParticipants": eodParticipantReport,
        "maxMessageSize": maxMessage,
        "messageValidationDebitorAgent": messageValidateDA,
        "messageValidationCreditorAgent": messageValidateCA,
        "messageValidationFedNowSimulator": messageValidateFNS,
        "customerId": customerId,

    }
    var payload = customerId
    useEffect(() => {
        getConfigrationParametersDetail(payload)

    }, [])
    useEffect(() => {
        if (data) {
            console.log('getConfigrationParametersDetail', data);
            setTransactionCCTDA(data?.transactionLimits[0].customerCreditTransferDA)
            setTransactionCCTCA(data?.transactionLimits[0].customerCreditTransferCA)
            setResponseTimeCCTDA(data?.responseTime[0].customerCreditTransferDA)
            setResponseTimeCCTCA(data?.responseTime[0].customerCreditTransferCA)
            setResponseTimeCCTFNS(data?.responseTime[0].customerCreditTransferFNS)
            setEodDetailReport(data?.EODReports[0].activityDetailsReport)
            setEodTotalReport(data?.EODReports[0].activityTotalsReport)
            setEodParticipantReport(data?.EODReports[0].listofParticipants)
            setMaxMessage(data?.messageSizeValidation[0].maxMessageSize)
            setMessageValidateDA(data?.messageValidations[0].debtorAgent)
            setMessageValidateCA(data?.messageValidations[0].creditorAgent)
            setMessageValidateFNS(data?.messageValidations[0].fedNowSimulator)
            // setEodbodProcessTime(data?.EODProcessing[0].EODBODProcessingTime)

        }
    }, [data])
    console.log('transactionCCTDA', transactionCCTDA);
    const handleEodSave = () => {
        console.log("updateParameters", updateParameters);
        updateConfigurationParametersDetails(savePayload).then(res=> {
            if(res?.data?.status === 'success'){
                snackbar.displayMsg(res?.data?.message,ALERT_SEVERITY.SUCCESS)
            }
            else{
                snackbar.displayMsg('configration parameters failed update',ALERT_SEVERITY.ERROR)
            }
        })
    }

    const handleTimeChange = (newTime) => {
        console.log('newTime',newTime);
        const utcFromLocal= (newTime).toISOString();
        // const getTimezoneOffset = value => value.getTimezoneOffset() * 60000;
        // const utcFromLocal = new Date(newTime.getTime() - getTimezoneOffset(newTime))
        // console.log('newTime',newTime);
        // const localTime = new Date(newTime);

        // const utcTime = localTime.toISOString().substr(11, 8);
    // Do whatever you need to do with the formatted time
    // For example, update the state
    // setEodbodProcessTime(formattedTime);
    console.log('utcFromLocal',utcFromLocal)
        // const currentUTC = new Date().toUTCString();
        // const defaultTime = new Date(selectedTime).toLocaleString();
        // setEodbodProcessTime(selectedTime);
        // console.log('fjgfhthtf',newTime.getHours());
        // console.log("uhjiu",dayjs(selectedTime).getHours());
        // console.log('Selected time:', selectedTime);

        // const formattedTime = dayjs(selectedTime).format('YYYY-MM-DDTHH:mm:ss');
        setEodbodProcessTime(utcFromLocal);
        // console.log('Selected time:', formattedTime);

    };

    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 110px)` }}>

            <TestCaseHeader
                title="Configuration Parameter"
                displayInLine={true}
            />

            <Box sx={{ p: 1 }}>
                <Box sx={{ display: 'flex', px: 2 }}>
                    <Typography sx={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }} >
                        Transaction Limits
                    </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                    <Grid container spacing={2} >
                        <Grid item xs={5} >
                            <Typography style={{ paddingLeft: "5%", paddingTop: '15px', textAlign: "left", fontSize: "16px", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, }} >
                                Customer Credit Transfer (Debtor Agent)
                            </Typography>
                        </Grid>
                        <Grid item xs={7}  >

                            <PayaptTextField

                                name="TlCctDa"
                                size="small"
                                type="number"
                                placeholder='Enter your value'
                                margin='dense'
                                className='signin-text-box'
                                // error={!!errors['email']}
                                helperText="Max $500,000"
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                                    style: { width: '40%' },
                                    inputProp: { min: 0, max: 500000 }
                                }}
                                onChange={(e) => setTransactionCCTDA(e.target.value)}
                                value={transactionCCTDA}
                            />

                        </Grid>
                        <Grid item xs={5} mt={-1}>
                            <Typography style={{ paddingLeft: "5%", paddingTop: '15px', textAlign: "left", fontSize: "16px",fontWeight: 100,fontFamily: 'Inter', fontStyle: 'normal' }} >
                                Customer Credit Transfer (Creditor Agent)
                            </Typography>
                        </Grid>
                        <Grid item xs={7} mt={-1}>
                            <PayaptTextField

                                name="TlCctCa"
                                size="small"
                                placeholder='Enter your value'
                                margin='dense'
                                className='signin-text-box'
                                // error={!!errors['email']}
                                helperText="Max $500,000"
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                                    style: { width: '40%' }
                                }}
                                onChange={(e) => setTransactionCCTCA(e.target.value)}
                                value={transactionCCTCA}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box my={1} style={{ textTransform: 'none' }} display={'flex'}>
                <Box sx={{ p: 1 }}>
                    <Box sx={{ display: 'flex', px: 2 }}>
                        <Typography sx={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                            Response Time
                        </Typography>
                    </Box>
                    <Grid container spacing={1} mt={-2}>
                        <Grid item xs={5} >
                            <Typography style={{ paddingLeft: "5%", paddingTop: '35px', textAlign: "left", fontSize: "16px",fontWeight: 100,fontFamily: 'Inter', fontStyle: 'normal' }} >
                                Customer Credit Transfer (Debtor Agent)
                            </Typography>
                        </Grid>
                        <Grid item xs={3} >
                            <span style={{ display: "flex" }}>(seconds)</span>
                            <PayaptTextField

                                name="TlCctCa"
                                size="small"
                                placeholder='Enter your value'
                                margin='dense'
                                className='signin-text-box'
                                // error={!!errors['email']}
                                InputProps={{
                                    style: { width: '98%' }
                                }}
                                onChange={(e) => setResponseTimeCCTDA(e.target.value)}
                                value={responseTimeCCTDA}

                            />
                        </Grid>

                        <Grid item xs={5} mt={-2}>
                            <Typography style={{ paddingLeft: "5%", paddingTop: '20px', textAlign: "left", fontSize: "16px",fontWeight: 100,fontFamily: 'Inter', fontStyle: 'normal' }} >
                                Customer Credit Transfer (Creditor Agent)
                            </Typography>
                        </Grid>
                        <Grid item xs={3} mt={-2}>
                            <PayaptTextField

                                name="TlCctCa"
                                size="small"
                                placeholder='Enter your value'
                                margin='dense'
                                className='signin-text-box'
                                // error={!!errors['email']}

                                onChange={(e) => setResponseTimeCCTCA(e.target.value)}
                                value={responseTimeCCTCA}
                                InputProps={{
                                    style: { width: '98%' }
                                }}
                            />
                        </Grid>


                        <Grid item xs={5} mt={-2}>
                            <Typography style={{ paddingLeft: "5%", paddingTop: '20px', textAlign: "left", fontSize: "16px",fontWeight: 100,fontFamily: 'Inter', fontStyle: 'normal' }} >
                                Customer Credit Transfer (FedNow Simulator)
                            </Typography>
                        </Grid>
                        <Grid item xs={3} mt={-2}>
                            <PayaptTextField

                                name="TlCctCa"
                                size="small"
                                placeholder='Enter your value'
                                margin='dense'
                                className='signin-text-box'
                                // error={!!errors['email']}

                                onChange={(e) => setResponseTimeCCTFNS(e.target.value)}
                                value={responseTimeCCTFNS}
                                InputProps={{
                                    style: { width: '98%' }
                                }}
                            />
                        </Grid>
                        <Grid item xs={1} mt={-18}>
                            <Box
                                sx={{

                                    width: 345,
                                    height: 'auto',
                                    // position: 'absolute',
                                    // right: "5px",
                                    // top: 415,
                                    backgroundColor: "#83f0cc",
                                    opacity: [0.9, 0.8, 0.7],
                                    borderRadius: "5px"
                                }}
                            >
                                <ul>
                                    <li style={{ textAlign: "left" }}>20 Sec max </li>
                                    <li style={{ textAlign: "left" }}>From the time of creation of request to response and the total time should not exceed 20                           sec</li>
                                    <li style={{ textAlign: "left" }}>FI can configure response time multiples of 1 sec </li>
                                    <li style={{ textAlign: "left" }}>1 Sec is min </li>
                                    <li style={{ textAlign: "left" }}>5 sec is reserved for Creditor FI out of 20 Sec </li>
                                    <li style={{ textAlign: "left" }}>If the gap between Debitor agent and FDN is more than 15 sec then FDN will reject the                             message</li>
                                </ul>
                            </Box>
                        </Grid>
                    </Grid>

                </Box>

            </Box>


            <Box sx={{ p: 2 }} mt={-2}>
                <Box sx={{ display: 'flex', px: 1 }}>

                    <Typography sx={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight:"bold", fontSize: "17px" }}>
                        EOD Processing
                    </Typography>

                </Box>
                <Box sx={{ display: 'flex', }}>
                    <Grid container spacing={2} px={2} >
                        <Grid item>

                            <Typography sx={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "16px", paddingTop: '15px' }}>
                                EOD/BOD Processing Time
                            </Typography>

                        </Grid>
                        <Grid item mx={24.5}>

                            {/* <PayaptTextField  
                              size="small"
                              type="time"
                              format="HH:mm:ss"
                              clearable
                              step="1"
                            //   ampm={false}
                            //   value={selectedTime}
                            value={data?.EODProcessing[0].EODBODProcessingTime}
                            //   onChange={handleChangeTime}
                              /> */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    views={['hours', 'minutes', 'seconds']}
                                    format="HH:mm:ss"
                                    clearable
                                    ampm={false}
                                    value={eodbodProcessTime}
                                    defaultValue={date}
                                    onChange={handleTimeChange}
                                    sx={{width:'250px'}}
                                    // renderInput={(props) => <input {...props} value={date} />}

                                />
                            </LocalizationProvider>
                            {/* <TimePicker
                              size="large"
                              onChange={handleTimeChange}
                              defaultValue={formattedDate && formattedDate}
                             value={selectedTime}
                            /> */}

                        </Grid>
                    </Grid>
                </Box>
                <hr />
                <Box sx={{ display: 'flex', px: 1 }}>

                    <Typography sx={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                        EOD Reports
                    </Typography>

                </Box>
                <Box>
                    <Box pl={2} pt={3}>
                        <Box display={'flex'}>

                            <Typography sx={{ width: '270px', textAlign: 'left', fontSize: "16px", fontWeight: 100, fontFamily: 'Inter', fontStyle: 'normal', }}>
                                Activity Details Report (camt.052)
                            </Typography>

                            <Grid mx={30}>

                                <XTwoWayLabelSwitch
                                    checked={eodDetailReport}
                                    onChange={(e) => setEodDetailReport(e.target.checked)}
                                />

                            </Grid>
                        </Box>
                        <Box pt={4} display={'flex'}>
                            <Typography sx={{ width: '270px', textAlign: 'left', fontSize: "16px", fontWeight: 100, fontFamily: 'Inter', fontStyle: 'normal', }}>
                                Activity Totals Report (camt.052)
                            </Typography>
                            <Grid mx={30}>
                                <XTwoWayLabelSwitch
                                    checked={eodTotalReport}
                                    onChange={(e) => setEodTotalReport(e.target.checked)}
                                />
                            </Grid>
                        </Box>
                        <Box pt={4} display={'flex'}>
                            <Typography sx={{ width: '270px', textAlign: 'left', fontSize: "16px", fontWeight: 100, fontFamily: 'Inter', fontStyle: 'normal', }}>
                                List of Participants (admi.998)
                            </Typography>
                            <Grid mx={30}>
                                <XTwoWayLabelSwitch
                                    checked={eodParticipantReport}
                                    onChange={(e) => setEodParticipantReport(e.target.checked)}
                                />
                            </Grid>
                        </Box>
                    </Box>

                </Box>

                {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 2 }}></Box> */}
            </Box>
            <Box sx={{ p: 2 }}>
                <Box >
                <Typography className='eod-header' sx={{ textAlign: 'left', fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px", padding: '5px' }}>
                    Message Size validation
                </Typography>
                </Box>
                <Box display={'flex'} >
                    <Grid container px={2} mt={2}>
                        <Grid item px={1}>

                            <Typography sx={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "16px",paddingTop: '10px' }}>
                                Max Message Size
                            </Typography>

                        </Grid>
                        <Grid item mx={31}>

                            <PayaptTextField
                                size="small"
                                onChange={(e) => setMaxMessage(e.target.value)}
                                value={maxMessage}
                                InputProps={{
                                    style: { width: '115%' }
                                }}
                            />

                        </Grid>
                    </Grid>
                    {/* <Typography>Max Message Size</Typography>
                    <Grid mx={45} sx={{ height: '30px' }}>
                        <PayaptTextField sx={{ height: '40px' }} />
                    </Grid> */}
                </Box>
                <Box pl={2}><hr /></Box>
                <Box px={1}>
                 <Typography className='eod-header' sx={{ textAlign: 'left', fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px",}}>
                    Message Validations
                    </Typography>
                </Box>
                <Box pl={4} pt={4} px={2}>
                    <Box display={'flex'}>
                        <Typography sx={{ width: '180px', textAlign: 'left', fontSize: "16px", fontWeight: 100, fontFamily: 'Inter', fontStyle: 'normal' }}>
                            Debtor Agent
                        </Typography>
                        <Grid mx={40}>
                            <XTwoWayLabelSwitch
                                checked={messageValidateDA}
                                onChange={(e) => setMessageValidateDA(e.target.checked)}
                            />
                        </Grid>
                    </Box>
                    <Box pt={4} display={'flex'}>
                        <Typography sx={{ width: '180px', textAlign: 'left', fontSize: "16px", fontWeight: 100, fontFamily: 'Inter', fontStyle: 'normal' }}>Creditor Agent</Typography>
                        <Grid mx={40}>
                            <XTwoWayLabelSwitch
                                checked={messageValidateCA}
                                onChange={(e) => setMessageValidateCA(e.target.checked)}
                            /></Grid>
                    </Box>
                    <Box pt={4} display={'flex'}>
                        <Typography sx={{ width: '180px', textAlign: 'left', fontSize: "16px", fontWeight: 100, fontFamily: 'Inter', fontStyle: 'normal' }}>Fednow Simulator</Typography>
                        <Grid mx={40}>
                            <XTwoWayLabelSwitch
                                checked={messageValidateFNS}
                                onChange={(e) => setMessageValidateFNS(e.target.checked)}
                            /></Grid>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: "flex" }} px={3} >
                <MUIButton
                 startIcon={<LeftCircleFilled style={{ fontSize: 18}}/>}
                 title="Back"
                 sx={{backgroundColor:'#F0F2F5',color:'#808080'}}
                 color="inherit"
                 onClick={() => navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`)}
                />
                <MUIButton
                    startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                    title="Save"
                    onClick={handleEodSave} 
                    sx={{mx:2}}
                />
            </Box>

        </Box>
    )
}


export default EodProcessing;
