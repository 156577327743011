import { Box} from '@mui/material';
import * as React from 'react';
import ChangePassword from './changePassword';
import UserProfile from './updateUserProfile';

const UserProfileContainer = () => {

  return (
   
    <Box sx={{overflowY: 'auto', maxHeight: '86vh',marginRight: '2%', width: '98%'}}>
          <Box>
            <UserProfile/>
          </Box>
          <Box>
             <ChangePassword />
          </Box>
  </Box>
  )
}

export default UserProfileContainer;
