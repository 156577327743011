import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name: 'common',
    initialState: {
        isUserAuthenticated: false,
        isUserProfileCompleted: false,
        enableCustomerOnboarding: false,
        isUserOnBoardingCompleted: false,
        isDashboard: false,
        selectedMenuKey: null,
        selectedSubMenuKey: null,
        selectedSubMenuTitle: null,
        collapseSideMenu: false,
        customerId: null,
        customerOrganizationName: null,
        customerSimTestDataId: null,
        userName: '',
        userId: null,
        pageSize: 10,
        date: "",
        userMenu: null,
        userDetails: {
            // userName: '',
            userRole: {}
        },
        customerOnBoarding: {
            subscriptionPlan: {},
            participationType: {},
            isOnboardingCompleted: false
        },
        organizationName: '',
        customerTestScenarioName: "",
        customerTypeId: null,
        customerTestRunId: null,
        selectedTestCasePayload: [],
        paymentServices:[]
    },
    reducers: {
        updateSelectedMenuKeys: (state, { payload }) => {
            const { selectedMenuKey, selectedSubMenuKey, selectedSubMenuTitle } = payload;
            state.selectedMenuKey = selectedMenuKey;
            state.selectedSubMenuKey = selectedSubMenuKey;
            state.selectedSubMenuTitle = selectedSubMenuTitle;
        },
        collapseSideMenu: (state, { payload }) => {
            state.collapseSideMenu = payload;
        },
        updateSignUpDetails: (state, { payload }) => {
            state.customerId = payload;
        },
        updateCustomerOrganization: (state, { payload }) => {
            state.customerOrganizationName = payload;
        },
        updateTestScenarioName: (state, { payload }) => {
            state.customerTestScenarioName = payload;
        },
        updateSimulatorData: (state, { payload }) => {
            state.customerSimTestDataId = payload;
        },
        updateCustomerTypeName: (state, { payload }) => {
            state.customerTypeId = payload;
        },
        updateCustomerTestRunId: (state, { payload }) => {
            state.customerTestRunId = payload;
        },
        updateUserId: (state, { payload }) => {
            state.userId = payload;
        },
        updateUserMenu: (state, { payload }) => {
            state.userMenu = payload;
        },
        updateAuthCheckParams: (state, { payload }) => {
            state.isUserAuthenticated = payload?.isUserAuthenticated;
            state.isUserProfileCompleted = payload?.isUserProfileCompleted || state.isUserProfileCompleted;
            state.enableCustomerOnboarding = payload?.enableCustomerOnboarding || state.enableCustomerOnboarding;
            state.isUserOnBoardingCompleted = payload?.isUserOnBoardingCompleted || state.isUserOnBoardingCompleted
            state.selectedMenuKey = payload?.selectedMenuKey;
            state.selectedSubMenuKey = payload?.selectedSubMenuKey;
            state.userDetails = payload?.userDetails || state?.userDetails;
            state.userName = payload?.userName || state?.userName;
            state.isDashboard = payload?.isDashboard;
            state.date = Date.now();
            state.organizationName = payload?.organizationName,
                state.customerId = payload?.customerId,
                state.customerTypeId = payload?.customerTypeId;
            state.paymentServices = payload?.paymentServices;
        },
        updateCustomerOnboarding: (state, { payload }) => {
            state.isUserOnBoardingCompleted = payload;
            state.enableCustomerOnboarding = false;
        },
        onLogout: (state, { payload }) => {
            state.isUserAuthenticated = false;
            state.isUserProfileCompleted = false;
            state.enableCustomerOnboarding = false;
            state.isUserOnBoardingCompleted = false;
            state.selectedMenuKey = null;
            state.selectedSubMenuKey = null;
            state.selectedSubMenuTitle = null;
            state.collapseSideMenu = false;
            state.userName = "",
                state.pageSize = 10,
                state.userDetails = {},
                state.customerId = null,
                state.customerOrganizationName = null,
                state.userId = null,
                state.customerSimTestDataId = null,
                state.customerTypeId = null
        },
        updateProfileCompletion: (state, { payload }) => {
            state.isUserProfileCompleted = payload?.isUserProfileCompleted || state.isUserProfileCompleted;
            state.date = Date.now();
        },
        updateOnboardingDetails: (state, { payload }) => {
            state.isUserProfileCompleted = payload?.isUserProfileCompleted || state.isUserProfileCompleted;
            state.enableCustomerOnboarding = payload?.enableCustomerOnboarding || state.enableCustomerOnboarding;
            state.isUserOnBoardingCompleted = payload?.isUserOnBoardingCompleted || state.isUserOnBoardingCompleted
            state.selectedMenuKey = payload?.selectedMenuKey;
            state.selectedSubMenuKey = payload?.selectedSubMenuKey;
            state.date = Date.now();
        },
        updateOnboardingCurrentStatus: (state, { payload }) => {
            console.log('updateOnboardingCurrentStatus', payload);
            const { subscriptionPlan, participationType, isOnboardingCompleted } = payload;
            state.customerOnBoarding.subscriptionPlan = subscriptionPlan || state?.customerOnBoarding?.subscriptionPlan;
            state.customerOnBoarding.participationType = participationType || state?.customerOnBoarding?.participationType;
            state.customerOnBoarding.isOnboardingCompleted = isOnboardingCompleted || state?.customerOnBoarding?.isOnboardingCompleted;
        },
        updateSelectedTestCasePayload: (state, { payload }) => {
            console.log('keysy-updateSelectedTestCasePayload', payload, state);

            const { testCaseId, statePayload } = payload;

            if (testCaseId || payload?.length) {
                if (testCaseId) {
                    const existingItemIndex = state.selectedTestCasePayload.findIndex(item => item.customerTestCaseId === testCaseId);
                    // console.log('keysy-existingItemIndex', existingItemIndex);
                    if (existingItemIndex !== -1) {
                        console.log('keysy-existingItemIndex-1', existingItemIndex);
                        const existingItem = state.selectedTestCasePayload[existingItemIndex];
                        const testDataToUpdate = statePayload[existingItemIndex]?.customerTestDataId[0];

                        const existingTestDataIndex = existingItem.customerTestDataId.findIndex(
                            testData => testData.data === testDataToUpdate?.data
                        );
                        // console.log('keysy-existingTestDataIndex-1', existingTestDataIndex, testDataToUpdate);
                        if (existingTestDataIndex !== -1) {
                            existingItem.customerTestDataId[0].data = testDataToUpdate?.data;
                            existingItem.customerTestDataId[0].messageId = testDataToUpdate?.messageId || undefined;
                        } else {
                            // existingItem.customerTestDataId.push({
                            //     data: testDataToUpdate.data,
                            //     messageId: testDataToUpdate.messageId || undefined
                            // });
                            // console.log('keysy-existingTestDataIndex-else', existingTestDataIndex);
                            existingItem.customerTestDataId[0].data = testDataToUpdate?.data;
                            existingItem.customerTestDataId[0].messageId = testDataToUpdate?.messageId || undefined;
                        }
                    }
                }
                else {
                    payload.forEach(newItem => {
                        const existingItemIndex = state.selectedTestCasePayload.findIndex(item => item.customerTestCaseId === newItem.customerTestCaseId);
                        // console.log('existing-existingItemIndex',existingItemIndex);
                        if (existingItemIndex !== -1) {
                            // console.log('existing-existingItemIndex-if',existingItemIndex);
                            const existingItem = state.selectedTestCasePayload[existingItemIndex];

                            // Unwrap Proxy object to ensure direct property access
                            const existingTestData = existingItem.customerTestDataId || [];
                            // console.log("newItem",newItem);
                            // const foundDataIndex = existingTestData?.findIndex(
                            //     testData => testData.data === newItem?.customerTestDataId[0]?.data
                            // );
                            // console.log('existing-existingItemIndex-if-existingItem',existingItemIndex,existingItem,existingTestData,foundDataIndex);
                            // if (foundDataIndex !== -1) {
                            //     console.log('existing-foundDataIndex-if',existingItemIndex,existingItem,existingTestData,foundDataIndex);
                            //     if (newItem.customerTestDataId[0]?.messageId !== undefined) {
                            //         existingTestData[foundDataIndex].messageId = newItem.customerTestDataId[0].messageId;
                            //     }
                            // } else {
                            //     console.log('existing-foundDataIndex-else',existingTestData);
                            if (newItem.customerTestDataId?.length > 0) {
                                const newData = newItem?.customerTestDataId[0]?.data;
                                const newMessageId = newItem?.customerTestDataId[0]?.messageId || undefined;
                                existingTestData[0].data = newData;
                                existingTestData[0].messageId = newMessageId;
                            }
                            else {

                                existingTestData.push({
                                    data: newData,
                                    messageId: newMessageId
                                });
                            }

                            existingItem.customerTestDataId = existingTestData;
                            // }
                        } else {
                            // console.log('existing-foundDataIndex-else-else');
                            state.selectedTestCasePayload.push(newItem);
                        }
                    });
                }

            } else {
                state.selectedTestCasePayload = [];
            }
        }
    },
});

export const { updateSelectedMenuKeys,
    collapseSideMenu,
    updateAuthCheckParams,
    onLogout, updateCustomerOnboarding,
    updateProfileCompletion,
    updateOnboardingDetails,
    updateOnboardingCurrentStatus, updateSignUpDetails, updateSimulatorData,
    updateCustomerOrganization, updateUserId, updateUserMenu,
    updateTestScenarioName,
    updateCustomerTypeName,
    updateCustomerTestRunId,
    updateSelectedTestCasePayload
} = commonSlice.actions;