import { Box, InputAdornment} from '@mui/material';
import React, { useEffect, useContext } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import '../../components/homeScreens/homeScreen.css';
import PayaptTextField from '../../shared/components/TextField';
import { INPUT_HEIGHT } from '../../shared/dims';
import { ExternalLinkIcon } from '../../assets/base64/images';
import { useListOfCustomerTestBatchMutation } from '../../redux/testRuns/BatchExecution.api';
import DraggableTestcase from '../BatchFiles/draggableTestcase';
import BatchBoxTitle from '../BatchFiles/BatchBoxTitle';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';
import { useSelector } from 'react-redux';

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	boxContainer: {
		border: '1px solid rgba(0,0,0, 0.1)',
		p: 2,
		height: '55vh',
		overflowY: 'auto',
	},
	searchContainer: {
		width: '100%',
	},
	contentContainer: {
		// p: 2
		mt: 1,
		maxHeight: '50vh',
		overflowY: 'auto',
	},
	cardStyle: {
		width: '100%',
		display: 'flex',
		alignItems: 'flex-start',
		px: 2,
		backgroundColor: 'rgba(0,0,0, 0.05)',
		my: 2,
		py: 2,
		borderRadius: '4px',
	},
	inputSx: {
		'.MuiOutlinedInput-notchedOutline': {
			borderRadius: '4px',
		},
		'.MuiInputBase-root': {
			height: INPUT_HEIGHT,
		},
	},
};

function BatchFileSelect(props) {
	const {
		setSelectedBatch,
		selectedBatch,
		onPreview,
		type = 'SA',
		onBatchSelectionChange,
		batchContextToRun,
		displayAllBatches = false,
		// customer
	} = props;
	const { value, customer } = useContext(DropdownValueContext);
	const { userRole } = useSelector((state) => state?.common?.userDetails);
	const { customerId } = useSelector((state) => state?.common);
	const [searchTerm, setSearchTerm] = React.useState('');

	const getPayload = () => {
		const payload = {
			testType: type,
		};
		if (searchTerm) {
			payload.batchName = searchTerm;
		}
		payload.status = displayAllBatches ? 'all' : 'individual';
		return payload;
	};

	var xPayload = {
		searchbytestbatchname: searchTerm,
		customerId: userRole?.name === 'Admin' || userRole?.name === 'Tester' ? customerId : customer,
		status: displayAllBatches ? 'all' : 'individual',
		paymentServiceId: value,
	};

	const [listOfCustomerTestBatch, { data: listOfBatch }] = useListOfCustomerTestBatchMutation();

	// console.log('DATAAAA', listOfBatch);
	// useEffect(() => {
	//     refetch()
	// }, [type, displayAllBatches])

	useEffect(() => {
		if (customer || customerId) {
			listOfCustomerTestBatch(xPayload);
		}
	}, [searchTerm, customer, displayAllBatches, value]);

	const handleOnSearchChange = (xValue) => {
		setSearchTerm(xValue);
	};

	const isBatchSelected = (batchId) => {
		const batchIndex = _.findIndex(batchContextToRun, (b) => b.batchId === batchId);
		return batchIndex >= 0;
	};
	return (
		<Box sx={styles.container}>
			<BatchBoxTitle title={`Batch Files (${_.size(listOfBatch)})`} />
			<Box sx={styles.boxContainer}>
				<Box sx={styles.searchContainer}>
					<PayaptTextField
						fullWidth
						sx={styles.inputSx}
						placeholder="Search here ..."
						value={searchTerm}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchIcon />
								</InputAdornment>
							),
						}}
						onChange={(event) => handleOnSearchChange(event.target.value)}
					/>
				</Box>
				{listOfBatch?.length !== 0 && listOfBatch !== undefined ? (
					<Box sx={styles.contentContainer}>
						{/* {isFetching && <PayaptLoader />} */}
						{listOfBatch?.map((xRes, xIdx) => (
							<DraggableTestcase
                                key={xIdx}
								title={xRes?.testRunBatchName}
								index={xIdx}
								className={
									xRes?.customerBatchId === selectedBatch?.customerBatchId
										? 'sharp-background arrow-right'
										: 'without-sharp'
								}
								onClick={() => {
									setSelectedBatch(xRes);
								}}
								item={xRes}
								actions={[
									{
										icon: <ExternalLinkIcon />,
										onClick: (e, data) => {
											e.stopPropagation();
											onPreview(true, xRes);
										},
									},
								]}
								showSelection={true}
								isSelected={isBatchSelected(xRes?.customerBatchId)}
								onSelectionChanged={(e, item) => {
									e.stopPropagation();
									onBatchSelectionChange(e.target.checked, item);
								}}
							/>
						))}
					</Box>
				) : (
					'NO DATA'
				)}
			</Box>
		</Box>
	);
}

export default BatchFileSelect;
