import { Box, Grid, InputAdornment, Typography, TextField, InputLabel } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import TestCaseHeader from '../testCaseHeader';
import PayaptTable from '../../shared/components/Table';

import {testCaseForParticipationType_columns} from '../../constants/constants';
import { useTestCountsBasedOnPartTypeMutation } from '../../redux/settings/settings.api';
import { useSelector } from 'react-redux';



const TestCaseForParticipationType = () => {
  const { customerId } = useSelector((state) => state?.common);
  const pageSize = useSelector((state) => state?.common?.pageSize);
  const { participationType } = useSelector(state => state?.common?.customerOnBoarding);
  const [pageNumber, setPageNumber] = useState(0);
  

  const [testCountsBasedOnPartType, {data}] = useTestCountsBasedOnPartTypeMutation();
    
    var initialPageOptions = {
      customerId: customerId,
      page:pageNumber,
      size: pageSize,
    }
    const [pageOptions, setPageOptions] = useState(initialPageOptions);

    const updatePageAndGetData = (options) => {
      const xPayload = {
        ...pageOptions,
        ...options
      }
      testCountsBasedOnPartType(xPayload)
    }
    const columns = testCaseForParticipationType_columns;

    useEffect(() => {
      setPageOptions(initialPageOptions)
      testCountsBasedOnPartType(initialPageOptions)
    },[])
   
    // const data = [
    //     {
    //       key: '1',
    //       description: 'Customer Credit Transfer',
    //       triggerMessage: 'pacs.008',
    //       inboundTestcases: '120',
    //       outboundTestcases: '',
    //     },
    //     {
    //       key: '2',
    //       description: 'Payment Status request',
    //       triggerMessage: 'pacs.028',
    //       inboundTestcases:'133',
    //       outboundTestcases: '80',
    //     },
    //     {
    //       key: '3',
    //       description: 'Return Request',
    //       triggerMessage: 'camt.056',
    //       inboundTestcases: '204',
    //       outboundTestcases: '',
    //     },
    //     {
    //       key: '4',
    //       description: 'Payment Return',
    //       triggerMessage: 'pacs.004',
    //       inboundTestcases: '100',
    //       outboundTestcases: '80',
    //     },
    //     {
    //       key: '5',
    //       description: 'Request For Payment (RFP)',
    //       triggerMessage: 'Pain.013',
    //       inboundTestcases: '',
    //       outboundTestcases: '',
    //     },
    //     {
    //       key: '6',
    //       description: 'RFP Cancellation Request',
    //       triggerMessage: 'camt.055',
    //       inboundTestcases: '',
    //       outboundTestcases: '',
    //     },
    //     {
    //       key: '7',
    //       description: 'Information Request',
    //       triggerMessage: 'camt.026',
    //       inboundTestcases: '180',
    //       outboundTestcases: 'Not Implemented',
    //     },
    //     {
    //       key: '8',
    //       description: 'Account Reporting Request',
    //       triggerMessage: 'camt.060',
    //       inboundTestcases: 'N/A',
    //       outboundTestcases: 'Not Implemented',
    //     },
    //     {
    //       key: '9',
    //       description: 'Account Debit/Credit notification',
    //       triggerMessage: 'camt.054',
    //       inboundTestcases: 'Not Implemented',
    //       outboundTestcases: 'N/A',
    //     },
    //     {
    //       key: '10',
    //       description: 'Message Reject',
    //       triggerMessage: 'admi.002',
    //       inboundTestcases: '135',
    //       outboundTestcases: '121',
    //     },
    //     {
    //       key: '11',
    //       description: 'FedNow Broadcast',
    //       triggerMessage: 'admi.004',
    //       inboundTestcases: '146',
    //       outboundTestcases: '',
    //     },
    //     {
    //       key: '12',
    //       description: 'Participant Broadcast',
    //       triggerMessage: 'admi.004',
    //       inboundTestcases: '',
    //       outboundTestcases: '111',
    //     },
    //     {
    //       key: '13',
    //       description: 'Retrieval Request',
    //       triggerMessage: 'admi.006',
    //       inboundTestcases: 'Not Implemented',
    //       outboundTestcases: 'N/A',
    //     },
    //     {
    //       key: '14',
    //       description: 'FedNow Participant File',
    //       triggerMessage: 'admi.998',
    //       inboundTestcases: '189',
    //       outboundTestcases: '',
    //     },
    //   ];
      
  
    return (
        <Box>
        <TestCaseHeader
          title="Test For Participation Type"
          subtitle= {<Box>
          <Typography variant="body2">{participationType?.participationTypeName}</Typography>
          <Typography variant="body2">Total Testcases ({data?.totalTestCaseCount})</Typography>
        </Box>}
        //   showSearch={{
        //     show: true,
        //     value: searchTerm,
        //     onChange: handleSearchOnChange
        //   }}
        //   showUser={{
        //     show: true,
        //     onClick: handleClick
        //   }}
        //   showFilter={{
        //     show: true,
        //     onClick: openModal
        //   }}
          // displayInLine = {true}
        />
  
        <Box my={2} style={{ textTransform: 'none' }}>
            <PayaptTable
              rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
              size="small"
              className="standalone_table"
              scroll={{ y: "50vh" }}
              columns={columns}
              data={!_.isEmpty(data?.data) ? data?.data : []}
              rowKey={(rec) => rec?.key}
              onChange={(pagination) =>
                updatePageAndGetData({
                  page: pagination.current - 1,
                  // search: '',
                  // sortBy: sorter?.field || pageOptions.sortBy,
                  // sortDir: getSortDirection(sorter.order) || pageOptions.sortDir,
                  // pageSize
                })
              }
              pagination={{
                pageSize,
                current: !_.isEmpty(data) ? data.page + 1 : 1,
                total: !_.isEmpty(data) ? data.totalCount : 0,
                showPrevNextJumpers: true,
                showTitle: true,
                hideOnSinglePage: true,
                // size: "small",
              }}
  
            />
        </Box>
      
  
        
      </Box>
  
          )
}

export default TestCaseForParticipationType;