import { Box, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
// import TestCaseHeader from '../testCaseHeader';
import PayaptTable from '../../shared/components/Table';
import { testbatch_data, testbatch_data_expand, testbatch_files_columns, testbatch_files_columns_expand } from '../../constants/constants';
import { EyeIcon, FileIcon } from '../../constants/menuIcons';
// import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import {  useGetTestBatchExecutionsMutation } from '../../redux/testRuns/BatchTestRuns.api';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import TestExecutionTable from '../../screens/testExecutionTable';
import TestRunResult from '../../shared/components/testResult';
import { generateCurrentEpochTimeValue } from '../../shared/utils';
import { Modal } from 'antd';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ViewLogs from '../../screens/viewLogs';
import ReviewTestCases from '../messages/reviewTestCases';
import PayaptTooltip from '../../shared/components/Tooltip';
import CircularProgressWithLabel from '../../shared/components/circularProgressWithLabel';
import BorderLinearProgress from '../../shared/components/linearProgress';
import { useGetAllBatchWithTestCaseMutation } from '../../redux/testRuns/BatchExecution.api';
import { getSortDirection } from '../../utils/sorterUtils';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';


const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
};
const TestExecution = (props) => {
  const { testRunsToKill, setTestRunsToKill, forceRefetchBatchApi, setForceRefetchBatchApi, 
    allRuns=false, canFilterData, filters, setFilterData ,searchValue ,
    // customer
  } = props;
  const { testType } = useParams();
  const pageSize = useSelector((state) => state?.common?.pageSize);
  const { userName , userRole} = useSelector((state) => state?.common?.userDetails);
  const [selectedTestScenario, setSelectedTestScenario] = useState({});
  const { value,customer } = useContext(DropdownValueContext);
  const { customerId } = useSelector((state) => state?.common);
  const initialPageOptions = {
    pageNo: 0,
    pageSize: pageSize,
    search:  searchValue? searchValue : null,
    sortBy: null,
    sortDir: 'desc',
    cusId: userRole?.name == 'Super Admin'? customer : customerId,
    filters,
    paymentServiceId:value
  }

  const testCasePayload = {
    pageNo: 0,
    pageSize: 10,
    search: searchValue ? searchValue : null,
    sortBy: null,
    sortDir: null,
    filters,
    cusId:userRole?.name == 'Super Admin'? customer : customerId,
    batchRunId: selectedTestScenario?.customerBatchRunId,
    paymentServiceId: value
  }

 
  const [getTestBatchExecutions, { data, isLoading }] = useGetTestBatchExecutionsMutation();
  const [getAllBatchWithTestCase, { data :testCases , isLoading: isTestCaseLoading }] = useGetAllBatchWithTestCaseMutation()

  React.useEffect(() => {
    if (forceRefetchBatchApi) {
      refetchTestExecutionQuery(initialPageOptions);
    }
  }, [forceRefetchBatchApi])
  React.useEffect(() => {
    if(selectedTestScenario?.customerBatchRunId){
      getAllBatchWithTestCase(testCasePayload)
    }
    else{
      getTestBatchExecutions(initialPageOptions)
    }
  }, [selectedTestScenario,searchValue,value,customer,testType,allRuns])


  const [isModalVisible, setModalVisibility] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [isXmlShow, setIsXmlShow] = useState(true);

  const onSelectChange = (newSelectedRowKeys, records, record) => {
    if(_.isEmpty(newSelectedRowKeys)){
      delete testRunsToKill[record?.customerBatchRunId]
      setTestRunsToKill({...testRunsToKill})
    } else {
      setTestRunsToKill({ ...testRunsToKill, [record?.customerBatchRunId]: newSelectedRowKeys });
    }
  };

  const getSortColumn = (key) => {
    switch (key) {
      case 'batchSubmittedDateTime':
        return 'batchSubmittedDateTime'
      case 'testrunBatchName':
        return 'TEST_CASE_NAME'
      case 'statusCheck':
        return 'TEST_RESULT_ID'
        case 'customerBatchRunId':
        return '_id'
      default:
        return null
    }
  }
  const getSortColumnTestCase = (key) => {
    console.log('key',key);
    switch (key) {
      case 'batchSubmittedDateTime':
        return 'submittedDateTime'
      case 'testRunBatchName':
        return 'TEST_CASE_NAME'
      case 'statusCheck':
        return 'TEST_RESULT_ID'
        case 'customerBatchRunId':
        return '_id'
      default:
        return null
    }
  }


  const rowSelection = {
    selectedRowKeys: testRunsToKill,
    // onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      className: "checkbox-red",
      disabled: record?.result !== 'Pending' && record?.result !== 'In Progress',
    }),
  };
  const columns = testbatch_files_columns;
  const column = testbatch_files_columns_expand;
  const [pageOptions, setPageOptions] = useState(initialPageOptions);
  const [pageOptionsTestCase, setPageOptionsTestCase] = useState(testCasePayload);

  async function refetchTestExecutionQuery(xOptions) {
    const options = {
      ...xOptions,
      filters: {
        ...xOptions.filters,
        status: allRuns? 'all' : 'individual'
      },
      search : searchValue
    }
    setPageOptions({
      ...options
    });
    await getTestBatchExecutions({ ...options }).then((xRes) => setForceRefetchBatchApi(false));
  }
  async function refetchTestCaseExecutionQuery(xOptions) {
    // console.log('xOptions',xOptions);
    const options = {
      ...xOptions,
      filters: {
        ...xOptions.filters,
        status: allRuns? 'all' : 'individual'
      },
      search : searchValue
    }
    setPageOptionsTestCase({
      ...options
    });
    await getAllBatchWithTestCase({ ...options }).then((xRes) => setForceRefetchBatchApi(false));
  }
  useEffect(() => {
    setPageOptions(initialPageOptions);
    // refetchTestExecutionQuery(initialPageOptions);
  }, [testType,customer])

  // useEffect(() => {
  //   // if (allRuns) {
  //     refetchTestExecutionQuery(initialPageOptions);
  //   // }
    
  // }, [allRuns])

  useEffect(() => {
    if(canFilterData){
      filterData(pageOptions);
      setFilterData(false)
    }
  }, [canFilterData])

  const setIsModalVisible = (isVisible, rec, entity) => {
    if (isVisible) {
      setSelectedRecord(rec);
      setSelectedEntity(entity)
    } else {
      setSelectedRecord({})
      setSelectedEntity(null)
    }
    setModalVisibility(isVisible)
  }


  const showDownload = async (id) => {
    const token = await localStorage.getItem('jwttoken');
    await fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/customerTestcaseBatch/downloadBatchRunDatas?batchRunId=${id}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download    
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${generateCurrentEpochTimeValue()}.xlsx`);
        // Append to html link element page    
        document.body.appendChild(link); // Start download    
        link.click();// Clean up and remove the link    
        link.parentNode.removeChild(link);
      })
  }

  const filterData = () => {
    const updatedPageOptions = {
      ...pageOptions,
      filters
    }
    const updatedTestCasePageOptions ={
      ...pageOptionsTestCase,
      filters,
      batchRunId:selectedTestScenario?.customerBatchRunId
    }
    setPageOptions(updatedPageOptions)
    // refetchTestExecutionQuery(updatedPageOptions)
    // refetchTestCaseExecutionQuery(updatedTestCasePageOptions)
    if(selectedTestScenario?.customerBatchRunId){
      refetchTestCaseExecutionQuery(updatedTestCasePageOptions)
    }
    else if(!selectedTestScenario?.customerBatchRunId){
      refetchTestExecutionQuery(updatedPageOptions)
    }
  }
  column[7].render = (data) => (
    <>
      <span style={{ textTransform: 'lowercase' }}>{Math.round(((data / 1000)) * 100) / 100} s</span>
    </>
  )
  column[8].render = (data, rec) => <TestRunResult data={data} rec={{...rec, openIndex: 0, testrunId: rec?.customerTestRunId}} setIsModalVisible={setIsModalVisible}/>
  // column[6].render = (data, rec) => (
  //   <>
  //     {data === "Fail" ? (
  //       <>
  //         <Tooltip title={rec?.failedReason || ""}>
  //           <span>{data === 'In Progress' ? <HourglassTopRoundedIcon sx={{ color: '#F2C046', width: '20px', height: '30.67px' }} /> : data === 'Fail' ? <CancelRoundedIcon sx={{ color: '#D65442', height: '20px', width: '20px' }} /> : data === 'Pass' ? <CheckCircleRoundedIcon sx={{ color: '#549E56', height: '20px', width: '20px' }} /> : <HourglassTopRoundedIcon sx={{ color: '#878787', height: '20px', width: '20px' }} />}</span>
  //           <span className={data === 'In Progress' ? 'testruns-results-progress' : data === 'Fail' ? 'testruns-results-failed' : data === 'Pass' ? 'testruns-results-success' : 'testruns-results-pending'}>{data}</span>
  //         </Tooltip>
  //       </>
  //     ) : (
  //       <>
  //         <span>{data === 'In Progress' ? <HourglassTopRoundedIcon sx={{ color: '#F2C046', width: '20px', height: '30.67px' }} /> : data === 'Fail' ? <CancelRoundedIcon sx={{ color: '#D65442', height: '20px', width: '20px' }} /> : data === 'Pass' ? <CheckCircleRoundedIcon sx={{ color: '#549E56', height: '20px', width: '20px' }} /> : <HourglassTopRoundedIcon sx={{ color: '#878787', height: '20px', width: '20px' }} />}</span>
  //         <span className={data === 'In Progress' ? 'testruns-results-progress' : data === 'Fail' ? 'testruns-results-failed' : data === 'Pass' ? 'testruns-results-success' : 'testruns-results-pending'}>{data}</span>
  //       </>
  //     )}
  //   </>
  // )
  column[9].render = (data, rec) =>  (
      <span  
      onClick={() => (rec?.result === 'Fail' || rec?.result === 'Pass' || rec?.result === 'Time out' || rec?.result === 'In Progress') ?
      setIsModalVisible(true, {...rec, testrunId: rec?.customerTestRunId}, 'viewLogs') : null}
        style={{ justifyContent: 'center', display: 'flex', 
        cursor: (rec?.result === 'Fail' || rec?.result === 'Pass' || rec?.result === 'Time out' || rec?.result === 'In Progress') ? 'pointer' :'not-allowed' 
        }}>
        <FileIcon />
      </span>
    );
    column[10].render = (data, rec) => (
      <PayaptTooltip title={rec?.testDataNumber}>
      <a onClick={() => setIsModalVisible(true, rec, 'viewData')} style={{ justifyContent: 'center', display: 'flex' }}>
        <span>{<EyeIcon />}</span>
      </a>
    </PayaptTooltip>

  )
  column[3].render = (data, rec) => (
    <>
      {/* {rec?.customerScenarioName}
      <br /> */}
      {rec?.customerTestCaseName}
      <br />
      {rec?.customerTestCaseCode}
    </>
  )
  columns[10].render = (data) => (
    <span
      onClick={() => showDownload(data)}
      style={{ justifyContent: 'center', display: 'flex' }}>
      <FileDownloadIcon style={{ color: '#ACACAC' }} />
    </span>
  )

  const getProgressValue = (value) => {
    const numarr = _.split(value, '/')
    if(_.size(numarr) == 2){
      const valPercentage = (Number(numarr[0])/Number(numarr[1]));
      return valPercentage*100
    }
    return 0;
  }
  const getCompletedStatus = (rec) => {
    const numarr = _.split(rec?.statusCheck, '/')
    if(_.size(numarr) == 2){

      return <span>
        <Typography>{`Completed ${numarr[0]} out of ${numarr[1]} test runs`}</Typography>
        <Typography sx={{fontSize: 14}}>{`Test cases Passed: ${rec?.passCount}`}</Typography>
        <Typography sx={{fontSize: 14}}>{`Test cases Failed: ${rec?.failCount}`}</Typography>
        <Typography sx={{fontSize: 14}}>{`Test cases Pending: ${rec?.pendingCount}`}</Typography>
        <Typography sx={{fontSize: 14}}>{`Test cases Inprogress: ${rec?.inprogressCount}`}</Typography>
        <Typography sx={{fontSize: 14}}>{`Test cases Time out: ${rec?.timeOutCount}`}</Typography>
      </span>
    }
    return '';
  }
  columns[9].render = (data, rec) => (
    <PayaptTooltip title = {getCompletedStatus(rec)}>
      <span>
        <Typography noWrap={true} sx={{ fontSize: 14 }}>{data}</Typography>
        <BorderLinearProgress variant="determinate" value={getProgressValue(rec?.statusCheck)} sx = {{ width: '90%'}}/>
      </span>
    </PayaptTooltip>
  )

        
    
      const getRowClassName = (index, rec) => {
        let className = "";
        if (
          rec.customerBatchRunId ===
          selectedTestScenario?.customerBatchRunId
        ) {
          className = "payapt-expanded-row";
        }
        return index % 2 === 0
          ? `${className} table-row-light`
          : `${className} table-row-dark`;
      };
      const updatePageAndGetData = (options,sorter,batch,pagination) => {
        if(selectedTestScenario?.customerBatchRunId && sorter && pageOptions.pageNo+1 === pagination.current){
          // if(  && options.pageNo===0){
           const xPayload = {
            ...pageOptionsTestCase,
            ...options,
            batchRunId : selectedTestScenario?.customerBatchRunId,
            sortBy : getSortColumnTestCase(sorter?.field)
          }
          console.log('updatePageAndGetData',xPayload);
          setPageOptionsTestCase({...xPayload})
          refetchTestCaseExecutionQuery(xPayload)
        // }
        // else{
        //   const xPayload = {
        //     ...pageOptionsTestCase,
        //     ...options,
        //     batchRunId : selectedTestScenario?.customerBatchRunId,
        //   }
        //   setPageOptionsTestCase({...xPayload})
        //   refetchTestCaseExecutionQuery(xPayload)
        // }
        }
        else{
        const xPayload = {
          ...pageOptions,
          ...options
        }
        console.log('xPayloadddd',xPayload);
        refetchTestExecutionQuery(xPayload)
        setPageOptions({...xPayload});
        // getTestExecution(xPayload);
      }
      }
      const updatePageAndGetDataTestCase=(options,pageNo)=>{
        const xPayload = {
          ...pageOptionsTestCase,
          pageNo: options.pageNo,
          batchRunId : selectedTestScenario?.customerBatchRunId,
          sortBy : pageOptionsTestCase.sortBy 
        }
        console.log('updatePageAndGetDataTestCase',xPayload ,'pageNo', pageNo ,'options',options );
        setPageOptionsTestCase({...xPayload})
        refetchTestCaseExecutionQuery(xPayload)
      }
      console.log('pageOptionsTestCase',pageOptionsTestCase);
  return (

    <Box sx={styles.container}>
      {/* <TestCaseHeader
          title="Test Execution Batch Files"
          subtitle="Some details related to running Message Flows goes here"
        /> */}
        <Box my={2}>
      
            <PayaptTable
             size="small"
             rowClassName={(record, index) => getRowClassName(index, record)}
             columns={columns}
             loading={isLoading}
            //  rowSelection={rowSelection}
             className='standalone_table'
             rowKey={(rec)=>rec.customerBatchRunId}
             onRow={(record, rowIndex) => {
              return {
                onClick: (event) => console.log('event',event)
                //  setTestcaseDetailsVisibility(false),
              };
            }}
             data={!_.isEmpty(data) ? data.customerBatchData : []}
            //  onChange={(pagination) => updatePageAndGetData ({
            //   pageNo: pagination.current - 1,
            //  })}
            onChange={(pagination, filters, sorter,batch = "yes") =>
              updatePageAndGetData({
                  pageNo: pagination.current - 1,
                  sortBy: sorter?.field || pageOptions.sortBy,
                  sortDir: getSortDirection(sorter.order) || pageOptions.sortDir,
              },sorter,batch,pagination )
          }
             pagination={{
              pageSize,
              current: !_.isEmpty(data) ? data.totalNumber + 1 : 1,
              total: !_.isEmpty(data) ? data.totalElements : 0,
              showPrevNextJumpers: true,
              showTitle: true,
              hideOnSinglePage: true,
              size: "small",
          }}
             expandable={{
              expandRowByClick: false,
              // expandedRowKeys: [selectedTestScenario?.customerBatchRunId],
                onExpand: (isExpanded, rec) => {
                  // console.log('rec',rec)
                  // console.log(testRunsToKill[rec?.customerBatchRunId])
                  // setRec(rec)
                  // // setTestcaseDetailsVisibility(false);
                  if (isExpanded) {
                    setSelectedTestScenario(rec);
                    return;
                  }
                  setSelectedTestScenario(null);
                },
                expandedRowRender: (record, i) => (
                    <PayaptTable
                    showHeader={false}
                    // customerBatchRunId
                    columns={column}
                    loading={isTestCaseLoading}
                    data={!_.isEmpty(testCases?.customerBatchData) ? testCases?.customerBatchData: []}
                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row' : 'table-row'}
                    scroll={null}
                    rowSelection={{
                      ...rowSelection,
                      onChange: (keys, records) => onSelectChange(keys, records, record),
                      checkStrictly: false,
                      selectedRowKeys: testRunsToKill[record?.customerBatchRunId] || [],
                      preserveSelectedRowKeys: true,
                  }}
                  onChange={(pagination, filters, sorter) =>
                    updatePageAndGetDataTestCase({
                        pageNo: pagination.current - 1,
                        sortBy: sorter?.field || pageOptions.sortBy,
                  sortDir: getSortDirection(sorter.order) || pageOptions.sortDir,
                    },sorter)
                }
                  pagination={{
                    pageSize,
                    current: !_.isEmpty(testCases) ? testCases.totalNumber + 1 : 1,
                    total: !_.isEmpty(testCases) ? testCases.totalElements : 0,
                    showPrevNextJumpers: true,
                    showTitle: true,
                    hideOnSinglePage: true,
                    size: "small",
                }}
                    className='nested_table'
                    rowKey={(rec) => rec?.customerTestRunId}
                    />
                    
                ),
              }}
            />
        </Box>
        <Modal
        onCancel={() => setIsModalVisible(false)}
        open={isModalVisible} footer={[]}
        width={'90%'}
        style = {{ width: '90%', height: '70%'}}
        closeIcon={<CancelOutlinedIcon style={{ color: "#001A11", backgroundColor: "white" }} />}
        destroyOnClose
      >
        {
          selectedEntity && selectedEntity === 'viewLogs' ?
            <ViewLogs item={selectedRecord} testcaseName={selectedRecord?.customerTestCaseCode} />
            : <ReviewTestCases show={isXmlShow} onChange={setIsXmlShow}
              testcaseDetails={{
                testCaseId: selectedRecord?.testCaseId, testCaseName: selectedRecord?.customerTestCaseCode,
                // customerTestCaseVersionId: selectedRecord?.customerTestCaseVersionId
                testCaseVersionId: selectedRecord?.testCaseVersionId
              }}
              showSingleTestcase={true}
              onClose={() => setIsModalVisible(false)}
              selectedTestScenario={{
                masterXMLPath: selectedRecord?.customerXmlFilePath,
                customerTestScenarioName: selectedRecord?.customerScenarioName,
                customerTestDescription: selectedRecord?.customerTestCaseName,
                customerTestRunId: selectedRecord?.customerTestRunId
              }}
            />
        }
      </Modal>
    </Box>

  )
}

export default TestExecution;
