import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import CircularProgressPercentage from '../CircularProgressPercentage';
import ProgressCircle from '../ProgressCircle';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import { StandaloneData } from './constants';
import '../../Dashboard/dashBoard.css';
import {
	useGetDistinctMsgCountMutation,
	useGetSAMsgCountMutation,
} from '../../../redux/dashboard/dashboard.api';
import CircularProgress from '../../../shared/components/CircularProgress';
import moment from 'moment';
import { DropdownValueContext } from '../../../shared/context/DropdownProvider';
import { useSelector } from 'react-redux';

const StandaloneMessage = (props) => {
	const { dateForDashboard, messageType } = props;
	const { value, customer } = useContext(DropdownValueContext);
	const { userRole } = useSelector((state) => state?.common?.userDetails);
	const { customerId } = useSelector((state) => state?.common);
	const [getDistinctMsgCount, { data }] = useGetDistinctMsgCountMutation();
	const safeDateForDashboard = dateForDashboard || [];

	var payload = {
		date: safeDateForDashboard.length > 0 ? moment(new Date(safeDateForDashboard[0])).format() : null,
		toDate: safeDateForDashboard.length > 1 ? moment(new Date(safeDateForDashboard[1])).format() : null,
		customerId: userRole?.name === 'Admin' || userRole?.name === 'Tester' ? customerId : customer,
		messageTypeId: messageType ? messageType : null,
		paymentServiceId: value,
	};
	useEffect(() => {
		if (customer || customerId) {
			getDistinctMsgCount(payload);
		}
	}, [dateForDashboard, customer, messageType, value]);

	const resultData = [
		{
			inbound: [
				{
					resultTitle: 'Executed',
					count: data?.inboundExecuted,
				},
				{
					resultTitle: 'Passed',
					count: data?.inboundPassed,
				},
				{
					resultTitle: 'Failed',
					count: data?.inboundFailed,
				},
			],
			outbound: [
				{
					resultTitle: 'Executed',
					count: data?.outboundExecuted,
				},
				{
					resultTitle: 'Passed',
					count: data?.outboundPassed,
				},
				{
					resultTitle: 'Failed',
					count: data?.outboundFailed,
				},
			],
		},
	];

	return (
		<div style={{ width: '100%' }}>
			<Box
				sx={{ marginTop: '10px' }}
			>
				<Box display={'flex'} sx={{ justifyContent: 'space-between' }}>
					<Typography
						className="test-data-review"
						sx={{ fontWeight: 600, textAlign: 'left', paddingLeft: '15px' }}
					>
						Test Cases Library
					</Typography>
					{/* <ChevronRightIcon sx={{marginRight:'15px'}}/> */}
				</Box>

				{/* <Box sx={{justifyContent:'center',alignItems:'center',padding:'10px', display:'flex'}}> */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between !important',
						alignItems: 'center',
						width: '100%',
					}}
				>
					<ProgressCircle
						iconType={<SouthIcon sx={{ color: '#1EA472', marginTop: '10px' }} />}
						circleIcon={
							<CircularProgress
								value={data?.inboundPercentage}
								size={45}
								strokeColor="#009966"
								strokeWidth={8}
								trailColor="#C1D9C7"
								trailWidth={6}
							/>
						}
						sx={{ backgroundColor: '#E5FFF7', borderRadius: '5px' }}
						total={data?.totalInboundTestCases}
						directionTitle="Inbound"
						testCases
						title="Test Cases"
						resultData={resultData[0]?.inbound}
					/>

					{/* <br/> */}
					{/* <Box> */}
					<ProgressCircle
						iconType={<NorthIcon sx={{ color: '#1EA472', marginTop: '10px' }} />}
						circleIcon={
							<CircularProgress
								value={data?.outboundPercentage}
								size={45}
								strokeColor="#009966"
								strokeWidth={8}
								trailColor="#C1D9C7"
								trailWidth={6}
							/>
						}
						sx={{ backgroundColor: '#E5FFF7', borderRadius: '5px' }}
						total={data?.totalOutboundTestCases}
						directionTitle="Outbound"
						testCases
						title="Test Cases"
						resultData={resultData[0]?.outbound}
					/>
					{/* </Box> */}
				</Box>
			</Box>
		</div>
	);
};

export default StandaloneMessage;
