import React, { useContext, useEffect } from "react";
import Dropdown from "../../shared/components/Dropdown";
import _ from "lodash";
import { useGetBatchNameDropDownMutation } from "../../redux/dashboard/dashboard.api";
import { DropdownValueContext } from "../../shared/context/DropdownProvider";

const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
            },
        },
    },
}

const BatchDropdown = (props) => {
    const { onBatchNameChange, valuee, isClear, customer } = props;
    const { value } = useContext(DropdownValueContext);
    const [getBatchNameDropDown, { data: batchNameFromApi }] = useGetBatchNameDropDownMutation();

    const batchPayload = {
        customerId: customer,
        paymentServiceId: value
    }

    useEffect(() => {
        if (customer) {
            getBatchNameDropDown(batchPayload)  
        } 
    }, [customer,value])

    // useEffect(() => {
    //     if(!_.isEmpty(batchNameFromApi)){
    //         onBatchNameChange(batchNameFromApi[0]?.batchId)
    //     }
    // }, [batchNameFromApi])

    return (
        <>
            <Dropdown
                placeholder='Select Batch'
                margin="dense"
                className="user-dropdown"
                style={{ width: '100%', height: '30px', marginTop: '-15px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                options={batchNameFromApi && batchNameFromApi}
                valueKey='batchId'
                labelKey='batchName'
                onChange={(e) => onBatchNameChange(e)}
                value={valuee}
                MenuProps={styles.menuProps}
                isClear={isClear}
                sx={{
                    boxShadow: 'none', boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                        border: 0,
                    },

                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                        border: 0,

                    }, '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' }, "& fieldset": {
                        border: "none",
                    },
                }}
            // disabled = {!!selectedRecord?.userId}
            />
        </>
    )
}
export default BatchDropdown