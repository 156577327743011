import { backendApi } from '../base.config';

function transformResponse(response) {
    const rrr = JSON.parse(response);
    console.log("🚀 ~ file: testRuns.api.js:32 ~ rrr:", { rrr });
    return { rrr };
}

export const testRunsApi = backendApi.injectEndpoints({
    endpoints: (builder) => ({
        getTestRuns: builder.mutation({
            query: (args) => ({
                url: `customerTestRun/customerTestRunList`,
                method: 'POST',
                body: { ...args },
            })
        }),
        addTestRuns: builder.mutation({
            query: (args) => ({
                method: "POST",
                url: `customerTestRun/addCustomerTestRun`,
                body: { ...args },
            }),
        }),
        getTestRunLogKeysData: builder.query({
            query: (args) => ({
                url: `customerTestRun/getTestRunLogPathDetails`,
                // url: `customerTestRun/getAllLogPath`,
                params: { ...args },
            }),
        }),
        getTestRunXMLDataByLogKeyId: builder.query({
            query: (args) => ({
                url: `customerTestRun/getAllLogPathDetails`,
                params: { ...args },
                responseHandler: (response) => response.text(),
            }),
        }),
        getDownloadUrl: builder.query({
            query: (args) => ({
                // headers: {
                //     "content-type": "text/plain",
                //     "content-disposition": "attachment",
                //     // "filename": "filename.txt"
                // },
                url: `customerTestRun/download2`,
                params: { ...args }
            }),
        }),
        getMessageType: builder.query({
            query: () => ({
                method: "GET",
                url: `customerTestRun/getAllmessageTypeName`,
            }),
            providesTags: [],
        }),
        getMessageId:builder.query({
            query: (args) => ({
                method: "GET",
                url: `customerTestRun/messageId`,
                params: { ...args }
            }),
        }),
        getHopLogs: builder.mutation({
            query: (args) => ({
                method: "POST",
                url: `customerTestRun/hopLogs`,
                params: { ...args } 
            })
        }),
    }),

});

export const {
    useGetTestRunsMutation,
    useAddTestRunsMutation,
    useGetTestRunLogKeysDataQuery,
    useGetTestRunXMLDataByLogKeyIdQuery,
    useLazyGetTestRunXMLDataByLogKeyIdQuery,
    useLazyGetDownloadUrlQuery,
    useGetMessageTypeQuery,
    useGetMessageIdQuery,
    useGetHopLogsMutation
} = testRunsApi;
