import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Grid, Paper, TextField } from '@mui/material';
import { Typography } from 'antd';
import { LeftCircleFilled } from '@ant-design/icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import TestCaseHeader from '../testCaseHeader';
import PayaptTextField from '../../shared/components/TextField';
import MUIButton from '../../shared/components/MUIButton';
import { INPUT_HEIGHT2 } from '../../shared/dims';
import { validationSchema } from './utils';
import "./userStyles.css";
import { ALERT_SEVERITY, APP_ROUTE_KEYS } from '../../shared/constants/constants';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useGetConnectivityOptionMutation, useSaveCertificateMutation } from '../../redux/users/users.api';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';

function Connectivity() {

  const navigate = useNavigate();
  const Snackbar = useContext(SnackbarContext);
  const { value } = useContext(DropdownValueContext);
  const { userRole } = useSelector((state) => state?.common?.userDetails);
  const customerId = useSelector((state) => state?.common?.customerId);
  const [getConnectivityOption, { data }] = useGetConnectivityOptionMutation();
  const [saveCertificate, { data: save }] = useSaveCertificateMutation();
  const [certificate, setCertificate] = useState();
  const [payGateIpAddress, setPayGateIpAddress] = useState();
  const [simulatorIpAddress, setSimulatorIpAddress] = useState();

  useEffect(() => {
    getConnectivityOption(customerId)
  }, [])

  
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    if (data) {
      console.log("getConnectivityOption", data);
      setCertificate(data?.data.certificate)
      setSimulatorIpAddress(data?.data.simulatorIpAddress)
      setPayGateIpAddress(data?.data.paymentGatewayIpAddress)

      setValue("certificate", data?.data.certificate)
      setValue("simulatorIpAddress",data?.data.simulatorIpAddress)
      setValue("paymentGatewayIpAddress",data?.data.paymentGatewayIpAddress)
    }
  },[data])
  const styles = {
    container: {
      display: 'flex',
      '& > *': { marginBottom: '10px' },
      textAlign: 'left',
      marginLeft: '2%',
    },
    textBox: {
      marginLeft: "100px"
    }
  };
  var savePayload = {
    "customerId": customerId,
    "certificate": certificate,
    "simulatorIpAddress": simulatorIpAddress,
    "paymentGatewayIpAddress": payGateIpAddress,
    "paymentServiceId":value
  }

  const handleSaveCertificate = async(res) => {
     if (res?.data?.status === 'success') {
      Snackbar.displayMsg(res?.data?.message, ALERT_SEVERITY.SUCCESS)
    } else {
      Snackbar.displayMsg('customer detail failed to update',ALERT_SEVERITY.ERROR)
    }
  }
  const onSubmit = async() => {
    const connectivityResults = await saveCertificate(savePayload)
    handleSaveCertificate(connectivityResults)
  };

  const onBack = () => {
    // dispatch(updateCurrentStep(currentStep - 1));
    userRole?.name == 'Super Admin' ?
      navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`)
      :
      navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`)
  }

  return (
    <>
      <TestCaseHeader title="Connectivity Options" />

      <Grid pt={1}>
        <Box sx={{ textAlign: "left", marginLeft: "2%" }}>
          <Box className="scrollable-content"  padding={"10px"}>
            <Box display={"flex"} mt={2}>
              <Grid container>
                <Grid item mt={1} width={'40%'}>
                  <Typography style={{ fontFamily: 'Inter', fontSize: "16px" }}>Enter Certificate</Typography>
                </Grid>

                <Grid item width={100}>
                  <Controller
                    control={control}
                    name="certificate"
                    // defaultValue="certificate"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        name="certificate"
                        error={!!errors.certificate}
                        helperText={errors.certificate?.message}
                        multiline
                        rows={7}
                        value={certificate}
                        onChange={(e) => {
                          field.onChange(e);
                          setCertificate(e.target.value);
                        }}
                        size="small"
                        sx={{ width: '350px', fontFamily: 'Inter' }}
                        placeholder="Enter Certificate"
                        inputProps={{ 'aria-label': 'Without label' }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box display={"flex"} mt={3}>
              <Grid container>
                <Grid item mt={1} width={'40%'}>
                  <Typography style={{ fontFamily: 'Inter', fontSize: "16px" }}>Simulator IP Address</Typography>
                </Grid>

                <Grid item width={100}>
                  <Controller
                    control={control}
                    name="simulatorIpAddress"
                    defaultValue=""
                    render={({ field }) => (
                      <PayaptTextField
                        {...field}
                        name="simulatorIpAddress"
                        error={!!errors.simulatorIpAddress}
                        helperText={errors.simulatorIpAddress?.message}
                        size="small"
                        sx={{ width: '350px', fontFamily: 'Inter' }}
                        placeholder="Enter Simulator IP"
                        margin="dense"
                        value={simulatorIpAddress}
                        onChange={(e) => {
                          field.onChange(e);
                          setSimulatorIpAddress(e.target.value);
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box display={"flex"} mt={2}>
              <Grid container>
                <Grid item mt={1} width={'40%'}>
                  <Typography style={{ fontFamily: 'Inter', fontSize: "16px" }}>Payment Gateway IP Address</Typography>
                </Grid>

                <Grid item width={100}>
                  <Controller
                    control={control}
                    name="paymentGatewayIpAddress"
                    defaultValue=""
                    render={({ field }) => (
                      <PayaptTextField
                        {...field}
                        name="paymentGatewayIpAddress"
                        error={!!errors.paymentGatewayIpAddress}
                        helperText={errors.paymentGatewayIpAddress?.message}
                        size="small"
                        sx={{ width: '350px', fontFamily: 'Inter' }}
                        placeholder="Enter Payment Gateway IP"
                        margin="dense"
                        value={payGateIpAddress}
                        onChange={(e) => {
                          field.onChange(e);
                          setPayGateIpAddress(e.target.value);
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Grid>
            <Box sx={{ textAlign: 'left', marginTop: '2%' }}>
              {(userRole?.name === 'Admin' || userRole?.name === 'Tester') ? 
                  <MUIButton
                  startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                  sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                  color="inherit"
                  title="Back"
                  onClick={onBack}
                />
              :
              <>
              <MUIButton
                startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                color="inherit"
                title="Back"
                onClick={onBack}
              />
              <MUIButton
                startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                title="Save"
                sx={{ mx: 2 }}
                onClick={handleSubmit(onSubmit)}
              />
              </>
                      }
            </Box>
          </Grid>
        </Box>
      </Grid>
    </>
  );
}

export default Connectivity;