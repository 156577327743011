import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import TestCaseHeader from '../testCaseHeader';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';
import { useNotificationMessageMutation, useNotificationXmlMutation } from '../../redux/Notifications/notifications.api';
import { Input, Modal } from 'antd';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSelector } from 'react-redux';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { ALERT_SEVERITY } from '../../shared/constants/constants';


const Notification = () => {
    const { TextArea } = Input;
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const { customer, value} = useContext(DropdownValueContext);
    const Snackbar = useContext(SnackbarContext);
    const { customerId } = useSelector((state) => state?.common);
    const [notificationsMessage] = useNotificationMessageMutation();
    const [notificationXml] = useNotificationXmlMutation();
    const [viewAll, setViewAll] = useState(false); // State to toggle view mode
    const [message, setMessage] = useState();
    console.log("message",message);
    const [isModalVisible, setModalVisibility] = useState(false);
    const [valuee, setValue] = useState([]);

    const payloadMsg = {
        customerId: (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer,
        paymentServiceId:value
    }

    useEffect(() => {
        if (customer && value || userRole?.name !== 'Super Admin') {
            notificationsMessage(payloadMsg).then(res => {
                console.log("Res33", res);
                setMessage(res?.data)
            });
        }
    }, [customer,value]);

    // useEffect(() => {
    //     if (userRole?.name !== 'Super Admin') {
    //         notificationsMessage(payloadMsg).then(res => {
    //             setMessage(res?.data)
    //         });
    //     }
    //   }, [userRole])

    const handleButtonClick = async (messageId,isVisible) => {
        const xmlPayload = {
            messageId:messageId
        };
        if (messageId) {
            await notificationXml(xmlPayload).then(res => {
                setValue(res?.data?.responseXml);
                setTimeout(() => {
                    setModalVisibility(isVisible);
                }, 500);
            }) 
        }
        else{
            setModalVisibility(false);
            Snackbar.displayMsg("XML cannot be viewed because the Message ID is null.",ALERT_SEVERITY.WARNING)
        }
       
        // You can do more here, like setting state or calling another function
    };

    const handleViewAllClick = () => {
        setViewAll(!viewAll); // Toggle the viewAll state
    };

    const displayedNotifications = viewAll ? message : message?.slice(0, 3);

    const onCancel = () => {
        setModalVisibility(false)
    };
    const downloadFile = () => {
        const element = document.createElement("a");
        const file = new Blob([valuee], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `Camt.054 Account Credit/Debit Notifications`
        document.body.appendChild(element);
        element.click();
        setModalVisibility(true)
    };

    if (value !== 1  && value !== 2) {
        return (
            <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
                <TestCaseHeader title="Notifications"
                    showTestCustomer={{
                        show: true,
                        
                    }}
                 />
                <Box mx={2} mt={2}>
                    <Card>
                        <CardContent>
                            <Typography variant="body1">No Data</Typography>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        );
    }

    
    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
            <TestCaseHeader
                title="Notifications"
                showTestCustomer={{
                    show: true,
                }}
            />
             
            {message?.length > 0 ? (
            <Box mx={2} mt={2}>
                {/* By using 'Grid container' without 'Grid item' wrapping each card, 
                  we make all cards stack vertically */}
                {displayedNotifications?.map((notification) => (
                    <Card key={notification.messageId} sx={{ mb: 2 }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body1" gutterBottom sx={{textAlign:"justify"}}>
                                {notification.notificationMessage}
                            </Typography>
                            <Button variant="text" sx={{ alignSelf: 'end',textTransform:'none',textDecoration: 'underline' }}
                              onClick={() => handleButtonClick(notification.messageId,true)}
                            >View Xml</Button>
                        </CardContent>
                    </Card>
                ))}
                
                 {message?.length > 4 && ( // Only show the "View All" button if there are more than 4 notifications   
                 <Box>
                    <Button variant="text" sx={{ mt: 3, textTransform:'none',display:"flex",
                    // position:"absolute",left:"90%",
                    textDecoration:"underline" }} onClick={handleViewAllClick}>
                        {viewAll ? 'View Less' : 'View All'}
                    </Button>
                </Box>
                )} 
            </Box>
            ) 
             :
            (
                <Box mx={2} mt={2}>
                <Card sx={{ mb: 2 }}>
                        <CardContent>
                            <Typography variant="body1" gutterBottom>
                                No Notifications
                            </Typography>
                        </CardContent>
                    </Card>
                    </Box>
            )
            } 
            <Modal
                onCancel={onCancel}
                open={isModalVisible} footer={[]}
                closeIcon={<CancelOutlinedIcon style={{ color: "#001A11", backgroundColor: "white" }} />}
                destroyOnClose
                width={"70%"}
                maskClosable={false}
            >
                <Box>
                    <TestCaseHeader 
                    title={"Camt.054 Account credit/debit notifications"}

                    showFileUpload={{
                        show: valuee ? true : false,
                        customIcon: <FileDownloadIcon />,
                        onClick: () => downloadFile()
                    }}
                    />
                    <div style={{ marginTop: 5 }}>

                        <TextArea
                            className="xml-txt-area"
                            rows={15}
                            readOnly
                            style={{ height: '100%' }}
                            value={valuee === null ? "NO DATA" : valuee}
                        />

                    </div>

                </Box>

            </Modal>
        </Box>
    );
}

export default Notification;
