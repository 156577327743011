import React from 'react';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Tooltip } from 'antd';
import { Box } from '@mui/system';
import PayaptTooltip from './Tooltip';

const displayResult = (data, rec) => {
  return (
    <>
        {data === "Fail" ? (
          <>
              <Box>
              <span>{data === 'In Progress' ? <HourglassTopRoundedIcon sx={{ color: '#F2C046', width: '20px', height: '30.67px' }} /> : data === 'Fail' ? <CancelRoundedIcon sx={{ color: '#D65442', height: '20px', width: '20px' }} /> : data === 'Pass' ? <CheckCircleRoundedIcon sx={{ color: '#549E56', height: '20px', width: '20px' }} /> : <HourglassTopRoundedIcon sx={{ color: '#878787', height: '20px', width: '20px' }} />}</span>
              <span className={data === 'In Progress' ? 'testruns-results-progress' : data === 'Fail' ? 'testruns-results-failed' : data === 'Pass' ? 'testruns-results-success' : 'testruns-results-pending'}>{data}</span>
              </Box>
          </>
        ) : (
          <Box>
            <span>{data === 'In Progress' ? <HourglassTopRoundedIcon sx={{ color: '#F2C046', width: '20px', height: '30.67px' }} /> : data === 'Fail' ? <CancelRoundedIcon sx={{ color: '#D65442', height: '20px', width: '20px' }} /> : data === 'Pass' ? <CheckCircleRoundedIcon sx={{ color: '#549E56', height: '20px', width: '20px' }} /> : <HourglassTopRoundedIcon sx={{ color: '#878787', height: '20px', width: '20px' }} />}</span>
            <span className={data === 'In Progress' ? 'testruns-results-progress' : data === 'Fail' ? 'testruns-results-failed' : data === 'Pass' ? 'testruns-results-success' : 'testruns-results-pending'}>{data}</span>
          </Box>
        )}
      </>
  )
}
const TestRunResult = (props) => {
    const { rec, data, setIsModalVisible} = props;
    if(rec?.receivedResponse){
      const resp = JSON.parse(rec?.receivedResponse);
      return (
        <>
          {
            _.map(resp, (val, key) => val?.statuscode ? (
              <PayaptTooltip title = {val?.failedReason}>
                <div key={key} style = {{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} onClick={() => data === 'Pass' || data === 'Fail'? setIsModalVisible(true, rec, 'viewLogs') : null}>
                  {val?.statuscode}
                  {displayResult(data, rec)}
                </div>
              </PayaptTooltip>
            ): <PayaptTooltip title = {val?.failedReason? val?.failedReason : ''}>
            <div style = {{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} onClick={() => data === 'Pass' || data === 'Fail'? setIsModalVisible(true, rec, 'viewLogs') : null}>
              {val?.statuscode}
              {displayResult(data, rec)}
            </div>
          </PayaptTooltip>)
          }
        </>
      )
    }
    return (
        <>
        {data === "Fail" ? (
          <>
            <Tooltip title={rec?.failedReason || ""}>
              <Box onClick={() => setIsModalVisible(true, rec, 'viewLogs')}>
              <span>{data === 'In Progress' ? <HourglassTopRoundedIcon sx={{ color: '#F2C046', width: '20px', height: '30.67px' }} /> : data === 'Fail' ? <CancelRoundedIcon sx={{ color: '#D65442', height: '20px', width: '20px' }} /> : data === 'Pass' ? <CheckCircleRoundedIcon sx={{ color: '#549E56', height: '20px', width: '20px' }} /> : <HourglassTopRoundedIcon sx={{ color: '#878787', height: '20px', width: '20px' }} />}</span>
              <span className={data === 'In Progress' ? 'testruns-results-progress' : data === 'Fail' ? 'testruns-results-failed' : data === 'Pass' ? 'testruns-results-success' : 'testruns-results-pending'}>{data}</span>
              </Box>
            </Tooltip>
          </>
        ) : (
          <Box onClick={() => data === 'Pass' ? setIsModalVisible(true, rec, 'viewLogs') : null}>
            <span>{data === 'In Progress' ? <HourglassTopRoundedIcon sx={{ color: '#F2C046', width: '20px', height: '30.67px' }} /> : data === 'Fail' ? <CancelRoundedIcon sx={{ color: '#D65442', height: '20px', width: '20px' }} /> : data === 'Pass' ? <CheckCircleRoundedIcon sx={{ color: '#549E56', height: '20px', width: '20px' }} /> : <HourglassTopRoundedIcon sx={{ color: '#878787', height: '20px', width: '20px' }} />}</span>
            <span className={data === 'In Progress' ? 'testruns-results-progress' : data === 'Fail' ? 'testruns-results-failed' : data === 'Pass' ? 'testruns-results-success' : 'testruns-results-pending'}>{data}</span>
          </Box>
        )}
      </>
    )
}

export default TestRunResult;
