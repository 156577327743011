import SchemaValidator from '../components/schemaValidator';
import OnboardingStepper from '../containers/onboardingContainer';
import { Route, Routes } from "react-router-dom"
import { Layout } from 'antd';
import AppHeader from '../components/header';
import RequestOtp from '../components/Forgot/RequestOtp';
import LoginContainer from './loginContainer';
import ForgotEmail from '../components/Forgot/ForgotEmail';
import CompleteYourProfile from '../components/welcome/CompleteYourProfile';
import ResetPass from '../components/Forgot/ResetPassword';
import NotFoundPage from '../components/NotFoundPage';
import ProfileUpdate from '../components/welcome/ProfileUpdate';
import ThanksForCompleteProfile from '../components/welcome/ThanksForCompleteProfile';

const { Header, Content, Footer } = Layout;

const UnauthenticatedRoutes = () => {
  console.log('[UnauthenticatedRoutes]');
  return (
    <Layout style={{ height: '100vh' }}>
      <Header className='app-header'><AppHeader /></Header>
      <Content style={{
        backgroundColor: '#ffffff', paddingTop: 0,
        paddingLeft: 0, marginTop: 0, maxHeight: '90vh', overflowY: 'auto'
      }}>
        <Routes>

          {/* un-Authenticated Routes */}
          <Route path='/signin' element={<LoginContainer isLogin={true} />} />
          <Route path='/signup' element={<LoginContainer />} />
          <Route path='/forgotemail' element={<ForgotEmail />} />
          <Route path='/requestotp' element={<RequestOtp />} />
          <Route path='/resetpassword' element={<ResetPass />} />
          <Route path='/completeprofile' element={<CompleteYourProfile />} />
          <Route path='/updateCompleteProfile' element={<ProfileUpdate />} />
          <Route path='/thankscompleteprofile' element={<ThanksForCompleteProfile />} />
          {/* <Route path='/customer/onboarding' element={<OnboardingStepper />} />
            <Route path='/completeprofile' element={<CompleteYourProfile />} /> <Route path='/customer/onboarding' element={<OnboardingMenu />} />
            <Route path='/onboardprocessdone' element={<OnBoardingProcessDone />} />
            <Route path='/simulator' element={<SchemaValidator />} />
            <Route path='/xmleditor' element={<XmlEditor />} />
            <Route path='/testcases' element={<Testcases />} /> 
            <Route path='/message'>
            <Route path='list' element={<MessageList />} />
            <Route path='testcases' element={<MessageTestcaseDetails />} />
            <Route path='list/testcases/details' element={<MessageDetail />} />
          </Route> */}

          {/* unknown routes */}
          <Route path="*" element={<NotFoundPage />} />

        </Routes>
      </Content>
      <Footer className='app-signin-footer'>
        <div className='signin-footer'>
          <span>Payapt &#169; 2023</span>
          <div className='privacy-terms'>
            <span>Privacy</span>&nbsp;&nbsp;
            <span>Terms</span>
          </div>
        </div>
      </Footer>
    </Layout>
  )
}

export default UnauthenticatedRoutes;