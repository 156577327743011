import * as yup from 'yup';

export const loginValidationSchema = yup.object().shape({
    email: yup.string().email().required("Email is required"),
    password: yup.string().required("Password is required"),
});

export const signupValidationSchema = yup.object().shape({
    customerOrganizationName: yup.string().required("Company Name is required"),
    firstName: yup.string().matches(/^[A-Za-z]+$/, 'First Name should contain only alphabetic characters').required("First Name is required"),
    lastName: yup.string().matches(/^[A-Za-z]+$/, 'Last Name should contain only alphabetic characters').required("Last Name is required"),
    email: yup.string().email().required("Email is required"),
    paymentServiceId: yup.string().required("Payment Service is required"),
    mobile: yup.string().matches(/^[1-9][0-9]*$/, 'Mobile number should contain only numbers').required("Contact Number is required"),
    password: yup.string().required("Password is required").min(8, 'Password is too short - should be 8 chars minimum')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    confirmPassword: yup.string().when('password', (password, field) =>
    password ? field.required("Password is required").oneOf([yup.ref('password')], 'Password and Confirm Password not matching') : field
  ),

});