import React from 'react';
import PropTypes from 'prop-types'
import { DashboardIcon, ExpandIcon, LogIcon, SettingsIcon, TestExecutionIcon, TestcasesIcon, UsersIcon } from '../../../constants/menuIcons';
import UploadTestCase from '../../../components/UploadTestCase/UploadTestCase';
import NotificationsIcon from '@mui/icons-material/Notifications';

const MenuItem = (props) => {
    const { item, isSelected, className = '', collapse = false, onClick,
        menuTitleKey, onExtraClick, isSubmenuItem = false } = props;

    const getMenuIcon = (key, isActive) => {
        switch (key) {
            case 'Dashboard':
                return <DashboardIcon isActive={isActive} />
            case 'Test Cases Library':
                return <TestcasesIcon isActive={isActive} />
            case 'Batches':
                return <TestExecutionIcon isActive={isActive} />
            case 'Executions':
                return <TestExecutionIcon isActive={isActive} />
            case 'Users':
                return <UsersIcon isActive={isActive} />
            case 'Customers':
                return <UsersIcon isActive={isActive} />
            case 'User Activity Logs':
                return <LogIcon isActive={isActive} />
            case 'Master Test Cases':
                return <TestcasesIcon isActive={isActive} />
            case 'Upload Test Cases':
                return <TestcasesIcon isActive={isActive} />
            case 'Pre-requisite data':
                return <TestcasesIcon isActive={isActive} />
            case 'Settings':
                return <SettingsIcon isActive={isActive} />
            case 'Reports/System Messages':
                return <LogIcon isActive={isActive} />
            case 'Notifications':
                return <NotificationsIcon isActive={isActive} sx={{color: isActive ? "#009966" : "#C6CBD1"}}/>
            // case 'System Messages':
            //     return <TestExecutionIcon isActive={isActive} />
            default:
                return <ExpandIcon isActive={isActive} />
        }
    }

    const getClassName = () => {
        if (collapse) {
            if (isSelected) {
                return 'payapt-menu-item-collapse-active'
            }
            if (item.disabled) {
                return 'payapt-menu-item-collapse disabled-item'
            }
            return 'payapt-menu-item-collapse'
        } else {
            if (isSelected) {
                return 'payapt-menu-item-active menu-item'
            }
            if (item.disabled) {
                return 'payapt-menu-item menu-item disabled-item'
            }
            return 'payapt-menu-item menu-item'
        }
    }

    const onExtraMenuClick = (e, item) => {
        e.stopPropagation();
        onExtraClick(e, item)
    }
    return (
        <div
            className={getClassName()}
            onClick={(e) => onClick(e, item)}
            style={isSubmenuItem ? { borderLeft: 'none' } : {}}
        >
            <span className='menu-item-text-container'>
                {/* {item?.icon && item?.icon(isSelected)} */}
                {getMenuIcon(item?.title ? item?.title : item?.description, isSelected)}
                {!collapse && <span className='menu-item-text'>{item[menuTitleKey]}</span>}
            </span>
            {!!item?.extra && !collapse && <span onClick={(e) => onExtraMenuClick(e, item)}>{item?.extra}</span>}
        </div>
    )
}

MenuItem.defaultProps = {
    item: {},
    isSelected: false,
    className: '',
    collapse: false,
    onClick: () => null,
    menuTitleKey: 'title',
}

MenuItem.PropTypes = {
    item: PropTypes.instanceOf(Object),
    isSelected: PropTypes.bool,
    collapse: PropTypes.bool,
    onClick: PropTypes.func,
    menuTitleKey: PropTypes.string,
    className: PropTypes.string
}

export default MenuItem;
