import React from 'react';
import PayaptAccordion from '../Accordion';
import MenuItem from './menuItem';
import PayaptPopoverMenu from './popoverMenu';
import PropTypes from 'prop-types'

const SubMenu = (props) => {
    const { item, children, isSelected, selectedChildKeys, 
        openSubmenu, expandIcon, showExpandIcon, collapse = false, onClick, selectionKey,
        menuTitleKey, submenuTitleKey, childrenKey, onSubmenuExtraClick, onMenuExtraClick, submenuSelectionKey, onSubmenuSelected,
        submenuContainerStyle
     } = props;

    const getSubmenuItems = () => {
        return (
            <div className='payapt-nested-menu-item'>
                {
                    _.map(children, (ch,index) => <MenuItem 
                                            key = {index}
                                            item = {ch} 
                                            isSelected = {_.includes(selectedChildKeys, ch[submenuSelectionKey])} 
                                            collapse = {collapse}
                                            onClick = {(e, pItem) => onClick(e,pItem,item)}
                                            menuTitleKey = {submenuTitleKey}
                                            onExtraClick = {onMenuExtraClick}
                                            isSubmenuItem = {true}
                                            />)
                }
            </div>
        )
    }
    return (
        <div>
            {
                collapse ? <PayaptPopoverMenu 
                            item = {item} 
                            menuTitleKey = {menuTitleKey} 
                            childrenKey = {childrenKey}
                            submenuTitleKey = {submenuTitleKey}
                            collapse = {collapse}
                            isSelected = {isSelected}
                            selectedMenuKeys = {selectedChildKeys}
                            onMenuClick = {(e, pItem) => onClick(e,pItem,item)}
                            />
            :
            <PayaptAccordion 
                title={<MenuItem item={item} className='payapt-submenu-item' collapse = {collapse} menuTitleKey = {menuTitleKey} onExtraClick = {onSubmenuExtraClick} isSelected = {isSelected} isSubmenuItem={true}/>}
                details = {getSubmenuItems()}
                defaultExpanded = {!collapse ? (openSubmenu || isSelected) : false}
                expandIcon = {expandIcon}
                showExpandIcon = {showExpandIcon}
                menuTitleKey = {menuTitleKey}
                accordionSx = {{
                    boxShadow: 'none', 
                    paddingRight: 0, 
                    borderLeft: isSelected ? '4px solid #009966': 'none',
                    backgroundColor: isSelected ? '#F0F2F5' : '#ffffff'
                }}
                titleSx = {{paddingLeft: 0.5, height: '40px', paddingRight: 0, margin: 0, 
                '.MuiAccordionSummary-content':{margin: 0, height: '40px'},
                '&.MuiAccordionSummary-root': { minHeight: '40px'}
            }}
                detailsSx = {{padding: 0}}
                onClick = {(e) => onSubmenuSelected(e, item)}
            />
}
        </div>
    )
}
SubMenu.defaultProps = {
    item: {},
    children: [],
    selectedChildKeys: [],
    isSelected: false,
    openSubmenu: false,
    expandIcon: null,
    showExpandIcon: true,
    collapse: false,
    onClick: () => null,
    selectionKey: 'key',
    menuTitleKey: 'title',
    submenuTitleKey: 'title',
    childrenKey: 'children'
}

SubMenu.PropTypes = {
    item: PropTypes.instanceOf(Object),
    children: PropTypes.arrayOf(String),
    selectedChildKeys: PropTypes.arrayOf(String),
    isSelected: PropTypes.bool,
    openSubmenu: PropTypes.bool,
    expandIcon: PropTypes.element,
    showExpandIcon: PropTypes.bool,
    collapse: PropTypes.bool,
    onClick: PropTypes.func,
    selectionKey: PropTypes.string,
    menuTitleKey: PropTypes.string,
    submenuTitleKey: PropTypes.string,
    childrenKey: PropTypes.string
}

export default SubMenu;
