import React, { useContext, useEffect, useState } from 'react';
import Dropdown from '../../shared/components/Dropdown';
import _ from 'lodash';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';
import { useGetPaymentServiceQuery } from '../../redux/customers/customer.api';
import { useSelector } from 'react-redux';

const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
            },
        },
    },
}


const FedTchDropdown = (props) => {
    // const { onChangeFedTch, value } = props;
    const { value, changeValue } = useContext(DropdownValueContext);
    const { paymentServices } = useSelector((state) => state?.common);
    const {data: paymentOptions} = useGetPaymentServiceQuery();
    const onFedTchSelection = (event) => {
        changeValue(event)
    };
     console.log("paymentServices",paymentServices);

    // const fedNowService = paymentServices.find(service => service.paymentServiceName === "FedNow");
    // const tchService = paymentServices.find(service => service.paymentServiceName === "TCH");

    // // Check if both FedNow and TCH are true
    // const bothTrue = fedNowService?.isApplicable && tchService?.isApplicable;

    const applicablePaymentServices = paymentServices?.filter(service => service.isApplicable) || [];

     // Check if the number of applicable payment services is greater than 1
     const showDropdown = applicablePaymentServices.length > 1;

    return (
        <>
             {/* {showDropdown && ( */}
                <Dropdown
                    placeholder='Select Payment Service'
                    margin="dense"
                    className="user-dropdown"
                    style={{ width: '100%', height: '42px', marginTop: '-20px' }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    options={applicablePaymentServices}
                    valueKey='paymentServiceId'
                    labelKey='paymentServiceName'
                    onChange={onFedTchSelection}
                    value={value}
                    MenuProps={styles.menuProps}
                />
            {/* )} */}
        </>
    );
}

export default FedTchDropdown;
