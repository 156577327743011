import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Tooltip } from 'antd';
import { useState } from 'react';
import { Box, Typography } from "@mui/material";
import _ from 'lodash';
import { getFilterOptions, testRunInitialFilters } from './TestRun/constants';
import { ALERT_SEVERITY, greyBorderColor, primaryColor } from '../../shared/constants/constants';
import TestCaseHeader from '../testCaseHeader';
import { getSortDirection } from '../../utils/sorterUtils';
import { ViewLogs } from '../../screens/viewLogs';
import PayaptTable from '../../shared/components/Table';
import './homeScreen.css'
import '../styles/message.css';
import { useSelector } from 'react-redux';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { BatchTestRunsMsg } from '../../shared/constants/AlertMessages';
import TestRunResult from '../../shared/components/testResult';
import { convertToLocalTime } from '../../shared/utils';
import { executionSummary_columns, executionSummary_exapanded_columns, testbatch_data } from '../../constants/constants';
import CircularProgress from '../../shared/components/CircularProgress';
import { useGetBatchExecutionSummaryMutation, useGetBatchSummaryBasedOnBatchRunMutation } from '../../redux/testRuns/BatchExecutionSummary.api';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  }
};

const BatchExecutionSummary = (props) => {

  const pageSize = useSelector((state) => state?.common?.pageSize);
  const { value, customer} = useContext(DropdownValueContext);
  const { userRole } = useSelector((state) => state?.common?.userDetails);
  const { customerId } = useSelector((state) => state?.common);
  const navigate = useNavigate();
  const [filters, setFilters] = React.useState(testRunInitialFilters || {});
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isXmlShow, setIsXmlShow] = useState(true);
  const [filterOptions, setFilterOptions] = useState(getFilterOptions([]))
  const [searchedText, setSearchedText] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [filterByAll, setFilterByAll] = useState(false);
  const [filterByBatchFile, setFilterByBatchFile] = useState(true);
  const [headerTitle, setHeaderTitle] = useState("");
  const [headerSubTitle, setHeaderSubTitle] = useState("");
  const [testRunsToKill, setTestRunsToKill] = useState({});
  const [forceRefetchBatchApi, setForceRefetchBatchApi] = useState(false);
  const [filterData, setFilterData] = useState(false);
  const Snackbar = useContext(SnackbarContext);
  const [getBatchSummary, { data: batchSummary ,isLoading }] = useGetBatchExecutionSummaryMutation()
  const [getExpandBatchSummary, { data: exapandBatchSummary ,isLoading: isExpandLoading}] = useGetBatchSummaryBasedOnBatchRunMutation()
  const [selectedPage, setSelectedPage] = useState(0)
  const [selectedBatch, setSelectedBatch] = useState({});
  const [expandedData, setExpandedData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  

  const columns = executionSummary_columns;
  const expandColumns = executionSummary_exapanded_columns

  const initialPageOptions = {
    pageNo: 0,
    pageSize: pageSize,
    sortBy: null,
    sortDir: null,
    search: null,
    cusId: (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer,
    paymentServiceId:value,
  }

  useEffect(() => {
    if (customer) {
      getBatchSummary(initialPageOptions)
    }
  }, [customer,value])

  useEffect(() => {
	  if (userRole?.name !== 'Super Admin' ) {
	    getBatchSummary(initialPageOptions)
	  }
	}, [userRole])

  useEffect(() => {

    setHeaderTitle("Batch Execution Summary")
    setHeaderSubTitle("Details of executed test cases for Batch Execution Summary")

  }, []);


  const updatePageAndGetData = (options) => {
    var pagechangedData = {
      pageNo: options.pageNo,
      pageSize: pageSize,
      sortBy: null,
      sortDir: null,
      search: null,
      cusId: (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer,
      paymentServiceId:value,
    }
    getBatchSummary(pagechangedData)
    setSelectedPage(options.pageNo)
  };



  const updatePageAndGetExpandData = (options) => {
    var pageChangeExpandData = {
      page: options.page,
      size: pageSize,
      customerBatchId: selectedBatch?.batchId
    }
    getExpandBatchSummary(pageChangeExpandData)
  }

  columns[3].render = (data) => (
    <>
      <span style={{ textTransform: 'lowercase' }}>{convertToLocalTime(data)}</span>
    </>
  )

  columns[10].render = (data) => (
    <CircularProgress
      value={data}
      size={45}
      strokeColor='#009966'
      strokeWidth={8}
      trailColor='#C1D9C7'
      trailWidth={6}
    />
  )


  expandColumns[3].render = (data) => (
    <>
      <span style={{ textTransform: 'lowercase' }}>{convertToLocalTime(data)}</span>
    </>
  )

  expandColumns[10].render = (data) => (
    <CircularProgress
      value={data}
      size={45}
      strokeColor='#009966'
      strokeWidth={8}
      trailColor='#C1D9C7'
      trailWidth={6}
    />
  )

  const getRowClassName = (index, rec) => {
    let className = "";
    if (
      rec.batchId ===
      selectedBatch?.batchId
    ) {
      className = "payapt-expanded-row";
    }
    return index % 2 === 0
      ? `${className} table-row-light`
      : `${className} table-row-dark`;
  };


  const handleExpand = async (expanded, rec) => {
    const intialExpandPageOptions = {
      page: 0,
      size: pageSize,
      customerBatchId: rec.batchId
    };

    if (expanded) {
      try {
        const response = await getExpandBatchSummary(intialExpandPageOptions);
        const expandDataWithKeys = response.data.customerBatchData.map((item, index) => ({ ...item, key: index.toString() }))
        setExpandedData(expandDataWithKeys)

      } catch (error) {
        console.error('Error fetching expanded table data:', error);
      }
      setSelectedBatch(rec);
      setExpandedRowKeys([rec.batchId])
    }
    else {
      setSelectedBatch(null);
      setExpandedRowKeys([])

    }
  };



  const onSearchChange = (valuee) => {
    setSearchedText(valuee);
    
    var searchChangedData = {
      pageNo: 0,
      pageSize: pageSize,
      sortBy: null,
      sortDir: null,
      search: valuee,
      cusId: (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer,
      paymentServiceId:value,
    }
    getBatchSummary(searchChangedData)

  }
  

  return (
    <Box sx={styles.container}>
      <TestCaseHeader
        title={headerTitle}
        subtitle={headerSubTitle}
        showSearch={{
          show: true,
          value: searchedText,
          onChange: onSearchChange
        }}
        showTestCustomer={{ show: true, 
         }}

      >

      </TestCaseHeader>
      <Box my={2}>

        <PayaptTable
          size="small"
          rowClassName={(record, index) => getRowClassName(index, record)}
          columns={columns}
          loading={isLoading}
          className='standalone_table'
          rowKey={(rec) => rec.batchId}
          // onRow={(record, rowIndex) => {
          //   console.log('rowIndex', rowIndex);
          //   return {
          //     onClick: (event) => console.log('event', event)
          //   };
          // }}
          data={!_.isEmpty(batchSummary?.customerBatchData) ? batchSummary?.customerBatchData : []}
          onChange={(pagination, filters, sorter) =>
            updatePageAndGetData({
              pageNo: pagination.current - 1,
            })
          }
          pagination={{
            pageSize,
            current: !_.isEmpty(batchSummary) ? batchSummary.totalPage + 1 : 1,
            total: !_.isEmpty(batchSummary) ? batchSummary?.totalElements : 0,
            showPrevNextJumpers: true,
            showTitle: true,
            hideOnSinglePage: true,
            size: "small",
          }}

          expandable={{
            expandRowByClick: false,
            onExpand: handleExpand,
            expandedRowKeys: expandedRowKeys,

            expandedRowRender: () => (
              <PayaptTable
                showHeader={false}

                columns={expandColumns}
                data={!_.isEmpty(expandedData) ? expandedData : []}
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row' : 'table-row'}
                scroll={null}
                loading={isExpandLoading}
                onChange={(pagination, filters, sorter) =>
                  updatePageAndGetExpandData({
                    page: pagination.current - 1,
                  })
                }
                pagination={{
                  pageSize,
                  current: !_.isEmpty(exapandBatchSummary) ? exapandBatchSummary.totalPage + 1 : 1,
                  total: !_.isEmpty(exapandBatchSummary) ? exapandBatchSummary?.totalElements : 0,
                  showPrevNextJumpers: true,
                  showTitle: true,
                  hideOnSinglePage: true,
                  size: "small",
                }}

                className='nested_table'

              />

            ),
          }}
        />
      </Box>


    </Box>
  )
}

export default BatchExecutionSummary;