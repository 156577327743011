import React from 'react';
import Dropdown from '../../shared/components/Dropdown';

const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
            },
        },
    },
}

const RoutingDropdown = (props) => {
    const { value } = props;
    return (
        <>
            <Dropdown
                placeholder=' Select Routing Number'
                margin="dense"
                className="user-dropdown"
                style={{ width: '100%', height: '42px', marginTop: '-20px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                // options={valueOptions}
                // valueKey='value'
                // labelKey='value'
                // onChange={(e) => onChange(e)}
                value={value}
                MenuProps={styles.menuProps}
            //    isClear={isClear}
            />
        </>
    )
}

export default RoutingDropdown;
